import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import LocaleProvider from './context/LocaleContext';
import { isProduction } from './helpers';
import { registerServiceWorker } from './helpers/pwa';
import { store } from './redux/store';

import './styles/global.scss';

const packageJson = require('../package.json'); // eslint-disable-line

if (isProduction && process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: `${packageJson.name}@${packageJson.version}`,
        environment: process.env.REACT_APP_APP_ENV,
        integrations: [new CaptureConsole({
            levels: ['error'],
        })],
    });
}

const container = document.getElementById('app-root') as Element;
const root = createRoot(container);

const helmetContext = {};

root.render(
    <StoreProvider store={store}>
        <LocaleProvider>
            <HelmetProvider context={helmetContext}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </HelmetProvider>
        </LocaleProvider>
    </StoreProvider>,
);

// Service worker registration
if (isProduction) {
    registerServiceWorker().catch(error => {
        console.log('ServiceWorker registration failed:', error);
    });
}
