import { FC, ReactElement } from 'react';

import { useEffectOnce } from 'react-use';

import { ErrorLabel } from '../../components';
import { NextYearCalendarList } from '../../containers';
import { CalendarListSkeletons } from '../../containers/CalendarList/subcomponents';
import { fetchNextYearCalendar } from '../../redux/nextYearCalendar/nextYearCalendarActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedNextYearCalendarProps {
    className?: string;
}

const ConnectedNextYearCalendar: FC<ConnectedNextYearCalendarProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.nextYearCalendarReducer.isLoading);
    const error = useTypedSelector(state => state.nextYearCalendarReducer.error);
    const nextYearCalendar = useTypedSelector(state => state.nextYearCalendarReducer.nextYearCalendar);

    useEffectOnce((): void => {
        dispatch(fetchNextYearCalendar());
    });

    return (
        <div className={className}>
            {isLoading && <CalendarListSkeletons />}

            {(!isLoading && error?.title) && <ErrorLabel text={error.title} />}

            {(!isLoading && nextYearCalendar.races.length > 0) && (
                <NextYearCalendarList nextYearCalendar={nextYearCalendar} />
            )}
        </div>
    );
};

export default ConnectedNextYearCalendar;
