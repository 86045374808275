import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { AccessToken } from '../AccessToken';
import { FetchResult, FetchResultType, rejectFetchError } from '../FetchResult';

export const fetchAuthToken = async (email: string, password: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/authentication/authenticate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
            return rejectFetchError('fetchAuthToken', response);
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[fetchAuthToken]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const fetchRefreshToken = async (): Promise<FetchResult<AccessToken, string>> => {
    try {
        const refreshTokenKey = 'refreshTokenKey';

        const response = await fetch(`${process.env.REACT_APP_API_URL}/authentication/refresh-token?refreshTokenKey=${refreshTokenKey}`, {
            method: 'POST',
            credentials: 'include',
        });

        if (!response.ok) {
            return rejectFetchError('fetchRefreshToken', response);
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[fetchRefreshToken]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const revokeRefreshToken = async (): Promise<FetchResult<AccessToken, string>> => {
    try {
        const refreshTokenKey = 'refreshTokenKey';

        const response = await authorizedFetch(`${process.env.REACT_APP_API_URL}/authentication/revoke-token?refreshTokenKey=${refreshTokenKey}`, {
            method: 'POST',
        });

        if (!response.ok) {
            return rejectFetchError('revokeRefreshToken', response);
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[revokeRefreshToken]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const sendPasswordResetMailApiCall = async (email: string): Promise<FetchResult<string, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/authentication/password-reset',
            queryParams: { email },
        });

        const response = await fetch(url, {
            method: 'POST',
        });

        if (!response.ok) {
            return rejectFetchError('sendPasswordResetMailApiCall', response);
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[sendPasswordResetMailApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const passwordResetApiCall = async (password: string, jwtToken: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/authentication/update-password',
            queryParams: { password },
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            return rejectFetchError('passwordResetApiCall', response);
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[passwordResetApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const activateAccountApiCall = async (password: string, jwtToken: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/authentication/activate-account',
            queryParams: { password },
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            return rejectFetchError('activateAccountApiCall', response);
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[activateAccountApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
