import { NavLinkProps, Route as RouteComponent, RouteProps } from 'react-router-dom';

import { IconName } from './components/Icon/Icon';
import { TranslatorFunction } from './helpers/trans';
import {
    CalendarPage,
    ConstructorStandingsPage,
    DriverStandingsPage,
    NotFoundPage,
    RaceWeekendPage,
    SettingsPage,
} from './pages';
import NextYearCalendarPage from './pages/NextYearCalendarPage/NextYearCalendarPage';

export type Route = RouteProps & {
    inNavigation?: boolean;
    key: string;
    label: string;
    icon?: IconName;
    path: string;
}

export interface NavigationItemProps extends Omit<NavLinkProps, 'children'> {
    key: string;
    label: string;
    icon?: IconName;
}

export type RouteParams = Record<string, string | undefined>;

export const RoutePaths = {
    home: (): string => '/',
    calendar: (): string => '/calendar',
    nextYearCalendar: (): string => '/calendar/2025',
    constructorStandings: (): string => '/constructor-standings',
    driverStandings: (): string => '/driver-standings',
    settings: (): string => '/settings',
    raceWeekend: (round = ':round', tab = ':tab'): string => `/calendar/2024/${round}/${tab}`,
};

const Routes: Route[] = [
    {
        key: 'home',
        path: RoutePaths.home(),
        inNavigation: false,
        label: 'routes.home',
        element: <CalendarPage />,
    },
    {
        key: 'calendar',
        path: RoutePaths.calendar(),
        inNavigation: true,
        label: 'routes.calendar',
        icon: 'calendar',
        element: <CalendarPage />,
    },
    {
        key: 'next-year-calendar',
        path: RoutePaths.nextYearCalendar(),
        inNavigation: false,
        label: 'routes.nextYearCalendar',
        element: <NextYearCalendarPage />,
    },
    {
        key: 'driver-standings',
        path: RoutePaths.driverStandings(),
        inNavigation: true,
        label: 'routes.driverStandings',
        icon: 'wheel',
        element: <DriverStandingsPage />,
    },
    {
        key: 'constructor-standings',
        path: RoutePaths.constructorStandings(),
        inNavigation: true,
        label: 'routes.constructorStandings',
        icon: 'garage',
        element: <ConstructorStandingsPage />,
    },
    {
        key: 'race-weekend',
        path: RoutePaths.raceWeekend(),
        inNavigation: false,
        label: 'routes.raceWeekend',
        element: <RaceWeekendPage />,
        children: (
            <RouteComponent path=":tab" element={<RaceWeekendPage />} />
        ),
    },
    {
        key: 'settings',
        path: RoutePaths.settings(),
        inNavigation: false,
        label: 'routes.settings',
        element: <SettingsPage />,
    },
    {
        key: '404',
        path: '*',
        label: 'routes.404',
        element: <NotFoundPage />,
    },
];

export const getNavigationRoutes = (trans: TranslatorFunction): NavigationItemProps[] => Routes
    .filter(route => route.inNavigation)
    .map(route => ({
        key: route.key,
        label: trans(route.label),
        icon: route.icon,
        to: route.path,
    }));

export default Routes;
