import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Error } from '../../helpers/japi/types/Error';
import { BeforeInstallPromptEvent } from '../../types';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type AppState = AsyncReduxState<{
    darkModeIsActive: boolean;
    pwaIsInstalled: boolean;
    pwaInstallEvent?: BeforeInstallPromptEvent;
    currentVersion: string;
    latestVersion: string;
}>;

const initialState: AppState = {
    ...initialAsyncReduxState,
    darkModeIsActive: false,
    pwaIsInstalled: false,
    currentVersion: '',
    latestVersion: '',
};

const appSlice = createSlice({
    name: 'appReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): AppState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDarkModeIsActive(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                darkModeIsActive: action.payload,
            };
        },
        setPwaIsInstalled(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                pwaIsInstalled: action.payload,
            };
        },
        setPwaInstallEvent(state, action: PayloadAction<BeforeInstallPromptEvent | undefined>): AppState {
            return {
                ...state,
                pwaInstallEvent: action.payload,
            };
        },
        setCurrentVersion(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                currentVersion: action.payload,
            };
        },
        setLatestVersion(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                latestVersion: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setDarkModeIsActive,
    setPwaIsInstalled,
    setPwaInstallEvent,
    setCurrentVersion,
    setLatestVersion,
} = appSlice.actions;

export default appSlice.reducer;
