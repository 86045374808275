import { RefObject } from 'react';

export const scrollToTop = (): void => {
    if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
    }
};

export const scrollToElementBottom = <T extends HTMLElement>(ref?: RefObject<T>, behavior: ScrollBehavior = 'auto'): void => {
    if (!ref?.current) return;

    ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior,
    });
};

export const scrollIntoView = <T extends HTMLElement>(ref?: RefObject<T>): void => {
    if (!ref?.current) return;

    ref.current.scrollIntoView({
        behavior: 'smooth',
    });
};
