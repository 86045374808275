import { getCalendarApiCall } from '../../entities/Calendar/CalendarService';
import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { setCalendar, setError, setIsLoading } from './calendarReducer';

export const fetchCalendar = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const calendarResponse = await getCalendarApiCall();

        if (!isFetchCollectionResultSuccessful(calendarResponse)) {
            if (calendarResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: calendarResponse.status,
                title: calendarResponse.error,
            }));

            return;
        }

        dispatch(setCalendar(calendarResponse.data));
    } catch (error) {
        console.error('[fetchCalendar]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
