import { isSSR } from '../../helpers';
import { isDOMException } from '../../helpers/error';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType, rejectFetchError } from '../FetchResult';
import { RaceResult, RaceResultsResource } from './RaceResult';
import { transformToRaceResult } from './RaceResultTransformers';

let raceResultAbortController = new AbortController();

export const getRaceResultApiCall = async (round: number): Promise<FetchCollectionResult<RaceResult[], string>> => {
    if (!isSSR) {
        raceResultAbortController.abort();
        raceResultAbortController = new AbortController();
    }

    try {
        const url = generateApiUrl({
            endpoint: `/2024/${round}/results.json`,
        });

        const response = await fetch(url, {
            signal: raceResultAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getRaceResultApiCall', response);
        }

        const raceResultsResource: RaceResultsResource = await response.json();
        const raceResult = raceResultsResource.MRData.RaceTable.Races.length > 0
            ? raceResultsResource.MRData.RaceTable.Races[0].Results.map(transformToRaceResult)
            : [];

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: raceResult,
        };
    } catch (error: unknown) {
        console.error('[getRaceResultApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
