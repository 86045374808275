import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';

import './RaceWeekendInfoSkeletons.scss';

interface RaceWeekendInfoSkeletonsProps {
    className?: string;
}

const RaceWeekendInfoSkeletons: FC<RaceWeekendInfoSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`race-weekend-info-skeletons ${className}`}>
        <div className="race-weekend-info-skeletons__info">
            <Skeleton className="race-weekend-info-skeletons__location" />
            <Skeleton className="race-weekend-info-skeletons__title" />
            <Skeleton className="race-weekend-info-skeletons__time-label" />
            <Skeleton className="race-weekend-info-skeletons__time" />
        </div>

        <Skeleton className="race-weekend-info-skeletons__dates" />
    </div>
);

export default RaceWeekendInfoSkeletons;
