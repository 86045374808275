/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconHelmet: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={20} viewBoxHeight={20} className={className}>
        <g strokeWidth={1} transform="translate(-220.000000, -5559.000000)" fill="#000000">
            <g transform="translate(56.000000, 160.000000)">
                <path style={{ fill: 'rgb(2,48,71)' }} d="M182,5414.833 C182,5415.979 181.062,5417 179.917,5417 L168.018,5417 L167.852,5417 C166.829,5417 166,5416.087 166,5415.065 L166,5413 L173.558,5413 C174.419,5413 175.184,5412.407 175.456,5411.59 L176.123,5409.611 C176.554,5408.316 175.59,5407 174.225,5407 L166.367,5407 C167.319,5404 170.049,5401.329 173.452,5401.101 C178.11,5400.789 182,5404.407 182,5409 L182,5414.833 Z M182,5403.018 C181.426,5402.252 180.747,5401.573 179.982,5401 C178.312,5399.749 176.246,5399 174,5399 C171.754,5399 169.688,5399.749 168.018,5401 C167.253,5401.573 166.574,5402.252 166,5403.018 C164.75,5404.687 164,5406.753 164,5409 L164,5414.833 C164,5417.042 165.791,5419 168,5419 L174,5419 L180,5419 C182.209,5419 184,5417.042 184,5414.833 L184,5409 C184,5406.753 183.25,5404.687 182,5403.018 L182,5403.018 Z" />
            </g>
        </g>
    </Svg>
);

export default IconHelmet;
/* eslint-enable max-len */
