import { FC, ReactElement } from 'react';

import { Navigation } from '../../compositions';
import useTrans from '../../hooks/useTrans';
import { getNavigationRoutes } from '../../Routes';

import './Menu.scss';

interface MenuProps {
    className?: string;
}

const Menu: FC<MenuProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const navigationRoutes = getNavigationRoutes(trans);

    return (
        <header className={`menu ${className}`}>
            <Navigation
                navigationLabel={trans('containers.menu.ariaLabel')}
                navigationItems={navigationRoutes}
                className="menu__navigation"
            />
        </header>
    );
};

export default Menu;
