import { CountryEnum } from '../Country/Country';

export interface RaceLocationResource {
    country: string;
    locality: string;
    lat: string;
    long: string;
}

export interface RaceLocation {
    country: string;
    countryAbbreviation?: CountryEnum;
    locality: string;
    lat: string;
    long: string;
}

export const defaultRaceLocation = (): RaceLocation => ({
    country: '',
    locality: '',
    lat: '',
    long: '',
});
