import React, { FC, ReactElement, useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import { ConnectedAppWrapper, ConnectedCacheBuster, ConnectedMenu } from './connectors';
import { scrollToTop } from './helpers/scroll';
import RouteComponents from './Routes';

const App: FC = (): ReactElement => {
    const location = useLocation();

    useEffect((): void => {
        scrollToTop();
    }, [location]);

    return (
        <ConnectedAppWrapper>
            <Routes>
                {RouteComponents.map(route => <Route {...route} key={route.key} />)}
            </Routes>
            <ConnectedCacheBuster />
            <ConnectedMenu />
        </ConnectedAppWrapper>
    );
};

export default App;
