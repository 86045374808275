import { isSSR } from '../../helpers';
import { isDOMException } from '../../helpers/error';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType, rejectFetchError } from '../FetchResult';
import { Calendar, CalendarResource } from './Calendar';
import { transformToCalendar } from './CalendarTransformers';

let calendarAbortController = new AbortController();

export const getCalendarApiCall = async (): Promise<FetchCollectionResult<Calendar, string>> => {
    if (!isSSR) {
        calendarAbortController.abort();
        calendarAbortController = new AbortController();
    }

    try {
        const url = generateApiUrl({
            endpoint: '/2024.json',
        });

        const response = await fetch(url, {
            signal: calendarAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getCalendarApiCall', response);
        }

        const calendarResource: CalendarResource = await response.json();
        const calendar = transformToCalendar(calendarResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: calendar,
        };
    } catch (error: unknown) {
        console.error('[getCalendarApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
