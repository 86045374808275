import { FC, PropsWithChildren, ReactElement } from 'react';

import { PageHeader } from './subcomponents';

import './Page.scss';

interface PageProps {
    title?: string; // @todo: make the title required
    previousAction?: string;
    className?: string;
}

const Page: FC<PropsWithChildren<PageProps>> = ({
    title = '',
    previousAction = '',
    className = '',
    children,
}): ReactElement => (
    <main className={`page ${className}`}>
        <PageHeader title={title} previousAction={previousAction} />
        {/* https://thenounproject.com/browse/icons/term/formula-one/ */}
        {children}
    </main>
);

export default Page;
