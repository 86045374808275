import { urls } from '../constants';

interface ApiGeneratorOptions {
    apiUrl?: string;
    endpoint: string;
    queryParams?: Record<string, string>;
}

export const generateApiUrl = (options: ApiGeneratorOptions): string => {
    const apiUrl = options.apiUrl || urls.api;

    const url = new URL(apiUrl + options.endpoint);

    if (options.queryParams) {
        const params = Object.entries(options.queryParams);

        for (let i = 0; i < params.length; i += 1) {
            const [key, value] = params[i];
            url.searchParams.set(key, value);
        }
    }

    return String(url);
};

export const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);

    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; i += 1) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};
