import { FC, ReactElement } from 'react';

import { Skeleton } from '../../components';
import { generateIdArray } from '../../helpers/array';

import './TableSkeletons.scss';

interface TableSkeletonsProps {
    amount?: number;
    className?: string;
}

const TableSkeletons: FC<TableSkeletonsProps> = ({
    amount = 20,
    className = '',
}): ReactElement => {
    const rows = generateIdArray(amount);

    return (
        <div className={`table-skeletons ${className}`}>
            {rows.map(row => (
                <Skeleton key={row} className="table-skeletons__row" />
            ))}
        </div>
    );
};

export default TableSkeletons;
