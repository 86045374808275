import { transformToCountryEnum } from '../Country/CountryTransformers';
import { transformToNationalityEnum } from '../Nationality/NationalityTransformers';
import { Driver, DriverResource } from './Driver';

export const transformToDriver = (
    driverResource: DriverResource,
): Driver => {
    const number = Number(driverResource.permanentNumber) !== 33
        ? Number(driverResource.permanentNumber)
        : 1;

    return ({
        id: driverResource.driverId,
        firstName: driverResource.givenName,
        lastName: driverResource.familyName,
        abbreviation: driverResource.code,
        nationality: transformToNationalityEnum(driverResource.nationality),
        nationalityAbbreviation: transformToCountryEnum(driverResource.nationality),
        number,
    });
};
