import { FC, ReactElement } from 'react';

import { Menu } from '../../containers';

interface ConnectedMenuProps {
    className?: string;
}

const ConnectedMenu: FC<ConnectedMenuProps> = ({
    className = '',
}): ReactElement => (
    <Menu className={className} />
);

export default ConnectedMenu;
