import { Constructor, ConstructorResource } from '../Constructor/Constructor';

export interface StandingsResource {
    position: string;
    positionText: string;
    points: string;
    wins: string;
    Constructor: ConstructorResource;
}

export interface ConstructorStandingsResource {
    MRData: {
        StandingsTable: {
            season: string;
            StandingsLists: {
                ConstructorStandings: StandingsResource[];
            }[];
        }
    };
}

export interface Standing {
    position: number;
    points: number;
    constructor: Constructor;
}

export interface ConstructorStandings {
    season: number;
    standings: Standing[];
}

export const defaultConstructorStandings = (): ConstructorStandings => ({
    season: new Date().getFullYear(),
    standings: [],
});
