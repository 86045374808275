import { FC, ReactElement } from 'react';

import { NextYearCalendar } from '../../entities/NextYearCalendar/NextYearCalendar';
import { NextYearCalendarListItem } from './subcomponents';

import './NextYearCalendarList.scss';

interface NextYearCalendarListProps {
    nextYearCalendar: NextYearCalendar;
    className?: string;
}

const NextYearCalendarList: FC<NextYearCalendarListProps> = ({
    nextYearCalendar,
    className = '',
}): ReactElement => (
    <ul className={`next-year-calendar-list ${className}`}>
        {nextYearCalendar.races.map(race => (
            <NextYearCalendarListItem
                key={race.round}
                race={race}
            />
        ))}
    </ul>
);

export default NextYearCalendarList;
