import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Race } from '../../entities/Race/Race';
import {
    formatDay,
    formatMonth,
    formatTime,
    formatWeekday,
} from '../../helpers/date';
import useTrans from '../../hooks/useTrans';
import { RoutePaths } from '../../Routes';
import { RaceDetailInfo } from './subcomponents';

import './RaceInfoCard.scss';

interface RaceInfoCardProps {
    isUpcoming: boolean;
    race: Race;
    className?: string;
}

const RaceInfoCard: FC<RaceInfoCardProps> = ({
    isUpcoming,
    race,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const location = useLocation();

    const { countryAbbreviation } = race.circuit.location;

    const isDetailView = location.pathname.includes(RoutePaths.raceWeekend(String(race.round), 'race'))
        || location.pathname.includes(RoutePaths.raceWeekend(String(race.round), 'sprint-race'))
        || location.pathname.includes(RoutePaths.raceWeekend(String(race.round), 'qualifying'))
        || location.pathname.includes(RoutePaths.raceWeekend(String(race.round), 'sprint-qualifying'));

    const dateClassNames = classNames('race-info-card__date', {
        'race-info-card__date--is-active': isUpcoming,
    }, className);

    return (
        <div className={`race-info-card ${className}`}>
            <div className="race-info-card__temp">
                <div className="race-info-card__info">
                    <p>
                        {countryAbbreviation && (
                            <span className={`fi fi-${countryAbbreviation} race-info-card__flag`} />
                        )}
                        {trans('compositions.raceInfoCard.location', {
                            country: race.circuit.location.country,
                            locality: race.circuit.location.locality,
                        })}
                    </p>

                    <h2 className="race-info-card__title">{race.name}</h2>

                    <h5 className="race-info-card__sub-title">
                        {trans('compositions.raceInfoCard.time')}
                    </h5>
                    <p>
                        {trans('compositions.raceInfoCard.times', {
                            startTime: formatTime(race.startDateTime),
                            endTime: formatTime(race.endDateTime),
                        })}
                    </p>
                </div>
                <div className={dateClassNames}>
                    <span className="race-info-card__weekday">
                        {formatWeekday(race.startDateTime)}
                    </span>
                    <span className="race-info-card__day">
                        {formatDay(race.startDateTime)}
                    </span>
                    <span className="race-info-card__month">
                        {formatMonth(race.startDateTime)}
                    </span>
                </div>
            </div>

            {(isUpcoming || isDetailView) && (
                <div className="race-info-card__detail-info">
                    <RaceDetailInfo
                        label="Qualifying"
                        session={race.qualifying}
                        className="race-info-card__detail-info-block"
                    />

                    {race.sprint && (
                        <RaceDetailInfo
                            label="Sprint"
                            session={race.sprint}
                            className="race-info-card__detail-info-block"
                        />
                    )}

                    {race.thirdPractice && (
                        <RaceDetailInfo
                            label="FP3"
                            session={race.thirdPractice}
                            className="race-info-card__detail-info-block"
                        />
                    )}

                    <RaceDetailInfo
                        label={race.sprint ? 'Sprint Qualifying' : 'FP2'}
                        session={race.secondPractice}
                        className="race-info-card__detail-info-block"
                    />

                    <RaceDetailInfo
                        label="FP1"
                        session={race.firstPractice}
                        className="race-info-card__detail-info-block"
                    />
                </div>
            )}
        </div>
    );
};

export default RaceInfoCard;
