import { FC, ReactElement } from 'react';

import { NavigationItemProps } from '../../Routes';
import { TabsNavigationItem } from './subcomponents';

import './TabsNavigation.scss';

interface NavigationProps {
    navigationLabel: string;
    navigationItems: (NavigationItemProps | false)[];
    className?: string;
}

const TabsNavigation: FC<NavigationProps> = ({
    navigationLabel,
    navigationItems,
    className = '',
}): ReactElement => (
    <nav aria-label={navigationLabel} className={`tabs-navigation ${className}`}>
        <ul className="tabs-navigation__list">
            {navigationItems.map(navItem => navItem && (
                <TabsNavigationItem
                    {...navItem}
                    key={navItem.key}
                    className="tabs-navigation__item"
                />
            ))}
        </ul>
    </nav>
);

export default TabsNavigation;
