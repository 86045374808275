import { FC, ReactElement } from 'react';

import { useEffectOnce } from 'react-use';

import { ErrorLabel } from '../../components';
import { TableSkeletons } from '../../compositions';
import { DriverStandingsList } from '../../containers';
import { fetchDriverStandings } from '../../redux/driverStandings/driverStandingsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedDriverStandingsProps {
    className?: string;
}

const ConnectedDriverStandings: FC<ConnectedDriverStandingsProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.driverStandingsReducer.isLoading);
    const error = useTypedSelector(state => state.driverStandingsReducer.error);
    const driverStandings = useTypedSelector(state => state.driverStandingsReducer.standings);

    useEffectOnce((): void => {
        dispatch(fetchDriverStandings());
    });

    return (
        <div className={className}>
            {isLoading && <TableSkeletons />}

            {(!isLoading && error?.title) && <ErrorLabel text={error.title} />}

            {(!isLoading && driverStandings.standings.length > 0) && (
                <DriverStandingsList driverStandings={driverStandings} />
            )}
        </div>
    );
};

export default ConnectedDriverStandings;
