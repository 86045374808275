import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Calendar, defaultCalendar } from '../../entities/Calendar/Calendar';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type CalendarState = AsyncReduxState<{
    calendar: Calendar;
}>;

const initialState: CalendarState = {
    ...initialAsyncReduxState,
    calendar: defaultCalendar(),
};

const calendarSlice = createSlice({
    name: 'calendarReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CalendarState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): CalendarState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCalendar(state, action: PayloadAction<Calendar>): CalendarState {
            return {
                ...state,
                calendar: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCalendar,
} = calendarSlice.actions;

export default calendarSlice.reducer;
