import { FC, ReactElement } from 'react';

import { RaceResult } from '../../entities/RaceResult/RaceResult';

import './RaceResultTable.scss';

interface RaceResultTableProps {
    raceResult: RaceResult[];
    className?: string;
}

const RaceResultTable: FC<RaceResultTableProps> = ({
    raceResult,
    className = '',
}): ReactElement => (
    <table className={`race-result-table ${className}`}>
        <thead className="race-result-table__head">
            <tr>
                <th>POS</th>
                <th>NO</th>
                <th>Driver</th>
                <th>Car</th>
                <th>Laps</th>
                <th>Time/Retired</th>
                <th>PTS</th>
            </tr>
        </thead>

        <tbody className="race-result-table__body">
            {raceResult.map(result => (
                <tr key={result.position} className="race-result-table__row">
                    <td>{result.position}</td>
                    <td>{result.driver.number}</td>
                    <td>
                        <span className="race-result-table__driver-name--abbreviation">{result.driver.abbreviation}</span>
                        <span className="race-result-table__driver-name--full">{`${result.driver.firstName} ${result.driver.lastName}`}</span>
                    </td>
                    <td className="race-result-table__constructor-name">{result.constructor.name}</td>
                    <td>{result.amountOfLaps}</td>
                    <td>{result.time}</td>
                    <td>{result.points}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default RaceResultTable;
