import { getConstructorStandingsApiCall } from '../../entities/ConstructorStandings/ConstructorStandingsService';
import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { setConstructorStandings, setError, setIsLoading } from './constructorStandingsReducer';

export const fetchConstructorStandings = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const constructorStandingsResponse = await getConstructorStandingsApiCall();

        if (!isFetchCollectionResultSuccessful(constructorStandingsResponse)) {
            if (constructorStandingsResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: constructorStandingsResponse.status,
                title: constructorStandingsResponse.error,
            }));

            return;
        }

        dispatch(setConstructorStandings(constructorStandingsResponse.data));
    } catch (error) {
        console.error('[fetchConstructorStandings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
