import { FC, ReactElement } from 'react';

import { useEffectOnce } from 'react-use';

import { ErrorLabel } from '../../components';
import { TableSkeletons } from '../../compositions';
import { ConstructorStandingsList } from '../../containers';
import { fetchConstructorStandings } from '../../redux/constructorStandings/constructorStandingsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedConstructorStandingsProps {
    className?: string;
}

const ConnectedConstructorStandings: FC<ConnectedConstructorStandingsProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.constructorStandingsReducer.isLoading);
    const error = useTypedSelector(state => state.constructorStandingsReducer.error);
    const constructorStandings = useTypedSelector(state => state.constructorStandingsReducer.standings);

    useEffectOnce((): void => {
        dispatch(fetchConstructorStandings());
    });

    return (
        <div className={className}>
            {isLoading && <TableSkeletons amount={10} />}

            {(!isLoading && error?.title) && <ErrorLabel text={error.title} />}

            {(!isLoading && constructorStandings.standings.length > 0) && (
                <ConstructorStandingsList constructorStandings={constructorStandings} />
            )}
        </div>
    );
};

export default ConnectedConstructorStandings;
