/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconOrderedList: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M22.4,29.8V36H4.1v-6.2H9V6.2H3.8V0h14v29.8H22.4z" />
        <path d="M25.8,74c0,1.8-0.4,3.4-1.2,4.7s-1.9,2.5-3.3,3.6s-3.3,2.4-5.9,4c-4.2,2.6-6.6,5.2-7,7.7H19v-4.6h7.2L25.8,100H0c0-3.1,0.4-5.7,1.1-7.8s1.9-3.9,3.6-5.6s4-3.7,7.1-6c1.7-1.2,2.9-2.4,3.8-3.5s1.3-2.3,1.3-3.5c0-1.2-0.3-2.1-1-2.7c-0.7-0.6-1.7-0.9-3-0.9c-1.7,0-2.9,0.6-3.7,1.7c-0.8,1.1-1.3,3-1.4,5.6L0.4,77c0.1-4.4,1.2-7.6,3.4-9.8S9.2,64,13.4,64c3.8,0,6.8,0.9,9,2.6C24.7,68.4,25.8,70.8,25.8,74z" />
        <rect x="40.3" y="14" width="59.7" height="8" />
        <rect x="40.3" y="78" width="59.7" height="8" />
    </Svg>
);

export default IconOrderedList;
/* eslint-enable max-len */
