import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChatEvent } from '../../entities/Chat/Chat';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ChatState = AsyncReduxState<{
    chatSocket?: WebSocket;
    eventLog: ChatEvent[];
}>;

const initialState: ChatState = {
    ...initialAsyncReduxState,
    eventLog: [],
};

const chatSlice = createSlice({
    name: 'chatReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ChatState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): ChatState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setChatSocket(state, action: PayloadAction<WebSocket | undefined>): ChatState {
            return {
                ...state,
                chatSocket: action.payload,
            };
        },
        setEventLog(state, action: PayloadAction<ChatEvent[]>): ChatState {
            return {
                ...state,
                eventLog: action.payload,
            };
        },
        addToEventLog(state, action: PayloadAction<ChatEvent>): ChatState {
            return {
                ...state,
                eventLog: [...state.eventLog, action.payload],
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setChatSocket,
    setEventLog,
    addToEventLog,
} = chatSlice.actions;

export default chatSlice.reducer;
