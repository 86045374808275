/* eslint-disable prefer-promise-reject-errors */
import { configureRefreshFetch } from 'refresh-fetch';

import { AccessToken } from '../entities/AccessToken';
import { fetchRefreshToken } from '../entities/Authentication/AuthenticationService';
import { isFetchResultSuccessful } from '../entities/FetchResult';
import { isSSR } from '.';

export const localStorageTokenKey = process.env.REACT_APP_API_LOCAL_STORAGE_TOKEN_KEY || 'bearerTokenAppKey';

interface RequestInitExtraOptions extends RequestInit {
    disableContentType?: boolean;
}

const fetchWithToken = async (url: string, options?: RequestInitExtraOptions): Promise<Response> => {
    const optionsWithToken: RequestInit = {
        ...options,
        credentials: 'include',
        headers: {
            Accept: 'application/vnd.api+json',
            ...(!options?.disableContentType && { 'Content-Type': 'application/vnd.api+json' }),
            ...options?.headers,
        },
    };

    if (!isSSR) {
        const token = localStorage.getItem(localStorageTokenKey);

        if (token) {
            const parsedToken: AccessToken = JSON.parse(token);

            optionsWithToken.headers = {
                ...optionsWithToken.headers,
                Authorization: `Bearer ${parsedToken.jwtToken}`,
            };
        }
    }

    return fetch(url, optionsWithToken);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldRefreshToken = (error: any): boolean => error?.response?.status === 401;

const updateRefreshToken = async (): Promise<void> => {
    if (isSSR) return;

    const token = localStorage.getItem(localStorageTokenKey);

    if (!token) {
        throw new Error('No token found');
    }

    const refreshResponse = await fetchRefreshToken();

    if (!isFetchResultSuccessful(refreshResponse)) {
        localStorage.removeItem(localStorageTokenKey);

        throw new Error('Refresh token was invalid');
    }

    localStorage.setItem(localStorageTokenKey, JSON.stringify(refreshResponse.data));
};

export const authorizedFetch = configureRefreshFetch({
    fetch: fetchWithToken,
    refreshToken: updateRefreshToken,
    shouldRefreshToken,
});
