import { FC, ReactElement } from 'react';

import './StandingsListItem.scss';

interface StandingsListItemProps {
    position: number;
    flag?: string;
    title: string;
    subTitle?: string;
    points: number;
    className?: string;
}

const StandingsListItem: FC<StandingsListItemProps> = ({
    position,
    flag = '',
    title,
    subTitle = '',
    points,
    className = '',
}): ReactElement => (
    <li className={`standings-list-item ${className}`}>
        <div className="standings-list-item__content">
            <p className="standings-list-item__position">{position}</p>

            {flag && <span className={`fi fi-${flag} standings-list-item__flag`} />}

            <div className="standings-list-item__title-wrapper">
                <p className="standings-list-item__title">{title}</p>
                {subTitle && <p className="standings-list-item__sub-title">{subTitle}</p>}
            </div>
        </div>

        <p className="standings-list-item__points">{points}</p>
    </li>
);

export default StandingsListItem;
