import { FC, ReactElement } from 'react';

import { NavigationItemProps } from '../../Routes';
import { NavigationItem } from './subcomponents';

import './Navigation.scss';

interface NavigationProps {
    navigationLabel: string;
    navigationItems: NavigationItemProps[];
    className?: string;
}

const Navigation: FC<NavigationProps> = ({
    navigationLabel,
    navigationItems,
    className = '',
}): ReactElement => (
    <nav aria-label={navigationLabel} className={`navigation ${className}`}>
        <ul className="navigation__list">
            {navigationItems.map(navItem => (
                <NavigationItem
                    {...navItem}
                    key={navItem.key}
                    className="navigation__item"
                />
            ))}
        </ul>
    </nav>
);

export default Navigation;
