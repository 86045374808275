// Abbreviations from: https://flagicons.lipis.dev/

export enum CountryEnum {
    AE = 'ae',
    AT = 'at',
    AU = 'au',
    AZ = 'az',
    BE = 'be',
    BH = 'bh',
    BR = 'br',
    CA = 'ca',
    CH = 'ch',
    CN = 'cn',
    DE = 'de',
    DK = 'dk',
    ES = 'es',
    FI = 'fi',
    FR = 'fr',
    GB = 'gb',
    HU = 'hu',
    IT = 'it',
    JP = 'jp',
    MC = 'mc',
    MX = 'mx',
    NL = 'nl',
    QA = 'qa',
    SA = 'sa',
    SG = 'sg',
    TH = 'th',
    US = 'us',
}
