import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import { transformToDriver } from '../Driver/DriverTransformers';
import { QualifyingResult, QualifyingResultResource } from './QualifyingResult';

export const transformToQualifyingResult = (
    qualifyingResultResource: QualifyingResultResource,
): QualifyingResult => ({
    position: Number(qualifyingResultResource.position),
    driver: transformToDriver(qualifyingResultResource.Driver),
    constructor: transformToConstructor(qualifyingResultResource.Constructor),
    q1Time: qualifyingResultResource.Q1,
    q2Time: qualifyingResultResource.Q2,
    q3Time: qualifyingResultResource.Q3,
});
