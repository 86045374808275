export const range = (start = 0, end = start + 1, step = 1): number[] => {
    const output = [];

    for (let i = start; i < end; i += step) {
        output.push(i);
    }

    return output;
};

export const generateIdArray = (amount: number): number[] => range(0, amount);

export const retrieveUniqueValues = <T>(array: T[]): T[] => (
    Array.from(new Set(array))
);

export const retrieveFirstAndLastValues = <T>(array: T[]): T[] => {
    const firstItem = array[0];
    const lastItem = array[array.length - 1];

    return [firstItem, lastItem];
};
