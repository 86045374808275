export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

export const getClosestNumber = (value: number, options: number[]): number => options.sort((current, comparison): number => {
    const currentOption = Math.abs(current - value);
    const comparisonOption = Math.abs(comparison - value);

    return currentOption - comparisonOption;
})[0];

export const randomInBetweenValue = (min: number, max: number): number => (
    Math.floor(Math.random() * (max - min + 1) + min)
);

export const convertNumberToCurrency = (number: number, locale?: string): string => number.toLocaleString(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: number % 1 ? 2 : 0,
    maximumFractionDigits: 2,
});
