/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconRedo: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={84.2} className={className}>
        <path d="M68,0l-9.4,8.6l18.2,19.8H27.9C12.5,28.4,0,40.9,0,56.3c0,18.5,12.8,27.9,38.1,27.9V71.5c-25.4,0-25.4-9.9-25.4-15.2c0-8.4,6.8-15.2,15.2-15.2h48.9L58.7,60.9l9.4,8.6l32-34.7L68,0z" />
    </Svg>
);

export default IconRedo;
/* eslint-enable max-len */
