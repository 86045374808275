import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import { Driver } from '../Driver/Driver';
import { transformToDriver } from '../Driver/DriverTransformers';
import { RaceResult, RaceResultResource } from './RaceResult';

const getStatus = (driver: Driver, status: string): string => {
    if (driver.lastName === 'Leclerc' && status === 'Accident') {
        return 'Inchident';
    }

    return status;
};

export const transformToRaceResult = (
    raceResultResource: RaceResultResource,
): RaceResult => {
    const driver = transformToDriver(raceResultResource.Driver);

    return ({
        position: Number(raceResultResource.position),
        driver,
        constructor: transformToConstructor(raceResultResource.Constructor),
        amountOfLaps: Number(raceResultResource.laps),
        time: raceResultResource.Time
            ? raceResultResource.Time.time
            : getStatus(driver, raceResultResource.status),
        points: Number(raceResultResource.points),
    });
};
