/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconCog: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M100,60V40H88.7c-0.9-3.7-2.4-7.1-4.3-10.3l8-8L78.3,7.6l-8,8c-3.2-1.9-6.6-3.3-10.3-4.3V0H40v11.3c-3.7,0.9-7.1,2.4-10.3,4.3l-8-8L7.6,21.7l8,8c-1.9,3.2-3.3,6.6-4.3,10.3H0v20h11.3c0.9,3.7,2.4,7.1,4.3,10.3l-8,8l14.1,14.1l8-8c3.2,1.9,6.6,3.3,10.3,4.3V100h20V88.7c3.7-0.9,7.1-2.4,10.3-4.3l8,8l14.1-14.1l-8-8c1.9-3.2,3.3-6.6,4.3-10.3H100z M50,70c-11,0-20-9-20-20c0-11,9-20,20-20s20,9,20,20C70,61,61,70,50,70z" />
    </Svg>
);

export default IconCog;
/* eslint-enable max-len */
