import trans from '../helpers/trans';

export enum FetchResultType {
    Abort = 'abort',
    Error = 'error',
    Success = 'success',
}

export interface SuccessfulFetchResult<T> {
    status: number;
    type: FetchResultType.Success;
    data: T;
    totalPages?: number;
}

export interface SuccessfulFetchCollectionResult<T> {
    status: number;
    type: FetchResultType.Success;
    data: T;
}

export interface UnsuccessfulFetchResult<E> {
    status?: number;
    type: FetchResultType.Error | FetchResultType.Abort;
    error: E;
}

export type FetchResult<T, E> = SuccessfulFetchResult<T> | UnsuccessfulFetchResult<E>;

export const isFetchResultSuccessful = <T, E>(result: FetchResult<T, E>): result is SuccessfulFetchResult<T> => (
    result.type === FetchResultType.Success
);

export type FetchCollectionResult<T, E> = SuccessfulFetchCollectionResult<T> | UnsuccessfulFetchResult<E>;

export const isFetchCollectionResultSuccessful = <T, E>(result: FetchCollectionResult<T, E>): result is SuccessfulFetchCollectionResult<T> => (
    result.type === FetchResultType.Success
);

export const rejectFetchError = (service: string, response: Response): UnsuccessfulFetchResult<string> => {
    let message = trans('errors.response.unknown');

    if (response.status === 400) message = trans('errors.response.400');
    if (response.status === 401) message = trans('errors.response.401');
    if (response.status === 403) message = trans('errors.response.403');
    if (response.status === 404) message = trans('errors.response.404');
    if (response.status === 410) message = trans('errors.response.410');
    if (response.status === 429) message = trans('errors.response.429');

    if (response.status === 500) message = trans('errors.response.500');
    if (response.status === 502) message = trans('errors.response.502');
    if (response.status === 504) message = trans('errors.response.504');

    return {
        status: response.status,
        type: FetchResultType.Error,
        error: `[${service}] ${message}`,
    };
};
