import {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import { LinkIconButton } from '../../compositions';
import { Calendar } from '../../entities/Calendar/Calendar';
import { scrollIntoView } from '../../helpers/scroll';
import { RoutePaths } from '../../Routes';
import { CalendarListItem } from './subcomponents';

import './CalendarList.scss';

interface CalendarListProps {
    calendar: Calendar;
    className?: string;
}

const CalendarList: FC<CalendarListProps> = ({
    calendar,
    className = '',
}): ReactElement => {
    const upComingCalendarListItemRef = useRef<HTMLLIElement>(null);

    useEffect((): void => {
        if (upComingCalendarListItemRef) {
            scrollIntoView<HTMLLIElement>(upComingCalendarListItemRef);
        }
    }, [upComingCalendarListItemRef]);

    return (
        <ul className={`calendar-list ${className}`}>
            {calendar.races.map(race => {
                const isUpcoming = calendar.upcomingRound === race.round;

                return (
                    <CalendarListItem
                        ref={isUpcoming ? upComingCalendarListItemRef : undefined}
                        key={race.round}
                        isUpcoming={isUpcoming}
                        race={race}
                        className="calendar-list__item"
                    />
                );
            })}


            <LinkIconButton
                icon="arrow-right"
                iconPos="right"
                text="See the 2025 calendar"
                to={RoutePaths.nextYearCalendar()}
                className="calendar-list__next-year-button"
            />
        </ul>
    );
};

export default CalendarList;
