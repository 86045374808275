import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SprintQualifyingResult } from '../../entities/SprintQualifyingResult/SprintQualifyingResult';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type SprintQualifyingResultState = AsyncReduxState<{
    sprintQualifyingResult: SprintQualifyingResult[];
}>;

const initialState: SprintQualifyingResultState = {
    ...initialAsyncReduxState,
    sprintQualifyingResult: [],
};

const sprintQualifyingResultSlice = createSlice({
    name: 'sprintQualifyingResultReducer',
    initialState,
    reducers: {
        resetSprintQualifyingResultState(): SprintQualifyingResultState {
            return {
                ...initialState,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): SprintQualifyingResultState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): SprintQualifyingResultState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSprintQualifiyingResult(state, action: PayloadAction<SprintQualifyingResult[]>): SprintQualifyingResultState {
            return {
                ...state,
                sprintQualifyingResult: action.payload,
            };
        },
    },
});

export const {
    resetSprintQualifyingResultState,
    setIsLoading,
    setError,
    setSprintQualifiyingResult,
} = sprintQualifyingResultSlice.actions;

export default sprintQualifyingResultSlice.reducer;
