import { FC, ReactElement, RefAttributes } from 'react';

import { NextYearRace } from '../../../../entities/NextYearCalendar/NextYearCalendar';
import {
    formatDate,
    formatDay,
    formatMonth,
    formatWeekday,
} from '../../../../helpers/date';
import useTrans from '../../../../hooks/useTrans';

import './NextYearCalendarListItem.scss';

interface NextYearCalendarListItemProps extends RefAttributes<HTMLLIElement> {
    race: NextYearRace;
    className?: string;
}

const NextYearCalendarListItem: FC<NextYearCalendarListItemProps> = ({
    race,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const { countryAbbreviation } = race.circuit.location;

    return (
        <li className={`next-year-calendar-list-item ${className}`}>
            <div className="next-year-calendar-list-item__info">
                <p>
                    {countryAbbreviation && (
                        <span className={`fi fi-${countryAbbreviation} next-year-calendar-list-item__flag`} />
                    )}
                    {trans('compositions.raceInfoCard.location', {
                        country: race.circuit.location.country,
                        locality: race.circuit.location.locality,
                    })}
                </p>

                <h2 className="next-year-calendar-list-item__title">{race.name}</h2>

                <h5 className="next-year-calendar-list-item__sub-title">
                    Date
                </h5>
                <p>
                    {trans('compositions.raceInfoCard.times', {
                        startTime: formatDate(race.startDate),
                        endTime: formatDate(race.endDate),
                    })}
                </p>
            </div>
            <div className="next-year-calendar-list-item__date">
                <span className="next-year-calendar-list-item__weekday">
                    {formatWeekday(race.endDate)}
                </span>
                <span className="next-year-calendar-list-item__day">
                    {formatDay(race.endDate)}
                </span>
                <span className="next-year-calendar-list-item__month">
                    {formatMonth(race.endDate)}
                </span>
            </div>
        </li>
    );
};

export default NextYearCalendarListItem;
