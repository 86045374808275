import { FC, ReactElement } from 'react';

import { Session } from '../../../../entities/Session/Session';
import {
    formatDay,
    formatMonth,
    formatTime,
    formatWeekday,
} from '../../../../helpers/date';
import useTrans from '../../../../hooks/useTrans';

import './RaceDetailInfo.scss';

interface RaceDetailInfoProps {
    label: string;
    session: Session;
    className?: string;
}

const RaceDetailInfo: FC<RaceDetailInfoProps> = ({
    label,
    session,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`race-detail-info ${className}`}>
            <h5 className="race-detail-info__label">{label}</h5>
            <div className="race-detail-info__date">
                <p>
                    {trans('compositions.raceInfoCard.times', {
                        startTime: formatTime(session.startDateTime),
                        endTime: formatTime(session.endDateTime),
                    })}
                </p>
                <p>
                    {formatWeekday(session.startDateTime)}
                    &nbsp;
                    {formatDay(session.startDateTime)}
                    &nbsp;
                    {formatMonth(session.startDateTime)}
                </p>
            </div>
        </div>
    );
};

export default RaceDetailInfo;
