import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultNextYearCalendar, NextYearCalendar } from '../../entities/NextYearCalendar/NextYearCalendar';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type NextYearCalendarState = AsyncReduxState<{
    nextYearCalendar: NextYearCalendar;
}>;

const initialState: NextYearCalendarState = {
    ...initialAsyncReduxState,
    nextYearCalendar: defaultNextYearCalendar(),
};

const nextYearCalendarSlice = createSlice({
    name: 'nextYearCalendarReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): NextYearCalendarState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): NextYearCalendarState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setNextYearCalendar(state, action: PayloadAction<NextYearCalendar>): NextYearCalendarState {
            return {
                ...state,
                nextYearCalendar: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setNextYearCalendar,
} = nextYearCalendarSlice.actions;

export default nextYearCalendarSlice.reducer;
