import { Circuit, CircuitResource } from '../Circuit/Circuit';

export interface NextYearRaceResource {
    round: string;
    season: string;
    raceName: string;
    startDate: string;
    endDate: string;
    Circuit: CircuitResource;
}

export interface NextYearRace {
    round: number;
    name: string;
    startDate: Date;
    endDate: Date;
    circuit: Circuit;
}

export interface NextYearCalendarResource {
    season: string;
    races: NextYearRaceResource[];
}

export interface NextYearCalendar {
    season: number;
    upcomingRound?: number;
    races: NextYearRace[];
}

export const defaultNextYearCalendar = (): NextYearCalendar => ({
    season: new Date().getFullYear() + 1,
    races: [],
});
