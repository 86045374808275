import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { Icon } from '../../../../components';
import { NavigationItemProps, RoutePaths } from '../../../../Routes';
import { NavLinkClassName } from '../../../../types';

import './NavigationItem.scss';

const NavigationItem: FC<NavigationItemProps> = ({
    label,
    icon,
    className = '',
    ...navigationItemProps
}): ReactElement => {
    const location = useLocation();

    const shouldBeActive = location.pathname === RoutePaths.home() && icon === 'calendar';

    const navLinkClassName: NavLinkClassName = ({ isActive }): string => classNames('navigation-item__link', {
        'navigation-item__link--is-active': isActive || shouldBeActive,
    });

    return (
        <li className={`navigation-item ${className}`}>
            <NavLink
                {...navigationItemProps}
                className={navLinkClassName}
            >
                {icon && <Icon name={icon} className="navigation-item__icon" />}
                {label && <span className="navigation-item__label">{label}</span>}
            </NavLink>
        </li>
    );
};

export default NavigationItem;
