import { FC, ReactElement } from 'react';

import {
    IconArrowDown,
    IconArrowLeft,
    IconArrowRight,
    IconBell,
    IconBin,
    IconBold,
    IconCalendar,
    IconCheck,
    IconChevronDown,
    IconCog,
    IconCross,
    IconGarage,
    IconGear,
    IconHelmet,
    IconItalic,
    IconMinus,
    IconOrderedList,
    IconPlus,
    IconRedo,
    IconSearch,
    IconSend,
    IconTeam,
    IconUndo,
    IconUnorderedList,
    IconVisibilityOff,
    IconVisibilityOn,
    IconWheel,
} from './set';

import './Icon.scss';

export interface SvgIconProps {
    className?: string;
}

const icons = {
    'arrow-down': IconArrowDown,
    'arrow-left': IconArrowLeft,
    'arrow-right': IconArrowRight,
    bell: IconBell,
    bin: IconBin,
    bold: IconBold,
    calendar: IconCalendar,
    check: IconCheck,
    'chevron-down': IconChevronDown,
    cog: IconCog,
    cross: IconCross,
    garage: IconGarage,
    gear: IconGear,
    helmet: IconHelmet,
    italic: IconItalic,
    minus: IconMinus,
    'ordered-list': IconOrderedList,
    plus: IconPlus,
    redo: IconRedo,
    search: IconSearch,
    send: IconSend,
    team: IconTeam,
    undo: IconUndo,
    'unordered-list': IconUnorderedList,
    'visibility-off': IconVisibilityOff,
    'visibility-on': IconVisibilityOn,
    wheel: IconWheel,
};

export type IconName = keyof typeof icons;

interface IconProps extends SvgIconProps {
    name: IconName;
}

const Icon: FC<IconProps> = ({
    name,
    className = '',
}): ReactElement | null => {
    const IconComponent = icons[name];

    return IconComponent
        ? <IconComponent className={`icon ${className}`} />
        : null;
};

export default Icon;
