import { FC, ReactElement } from 'react';

import { SprintQualifyingResult } from '../../entities/SprintQualifyingResult/SprintQualifyingResult';

import './SprintQualifyingResultTable.scss';

interface SprintQualifyingResultTableProps {
    sprintQualifyingResult: SprintQualifyingResult[];
    className?: string;
}

const SprintQualifyingResultTable: FC<SprintQualifyingResultTableProps> = ({
    sprintQualifyingResult,
    className = '',
}): ReactElement => (
    <table className={`qualifying-result-table ${className}`}>
        <thead className="qualifying-result-table__head">
            <tr>
                <th>POS</th>
                <th>NO</th>
                <th>Driver</th>
                <th>Car</th>
                <th>SQ1</th>
                <th>SQ2</th>
                <th>SQ3</th>
            </tr>
        </thead>

        <tbody className="qualifying-result-table__body">
            {sprintQualifyingResult.map(result => (
                <tr key={result.position} className="qualifying-result-table__row">
                    <td>{result.position}</td>
                    <td>{result.driver.number}</td>
                    <td>
                        <span className="qualifying-result-table__driver-name--abbreviation">{result.driver.abbreviation}</span>
                        <span className="qualifying-result-table__driver-name--full">{`${result.driver.firstName} ${result.driver.lastName}`}</span>
                    </td>
                    <td className="qualifying-result-table__constructor-name">{result.constructor.name}</td>
                    <td>{result.sq1Time}</td>
                    <td>{result.sq2Time || ''}</td>
                    <td>{result.sq3Time || ''}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default SprintQualifyingResultTable;
