import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { Page } from '../../components';
import { ConnectedNextYearCalendar } from '../../connectors';
import useTrans from '../../hooks/useTrans';
import { RoutePaths } from '../../Routes';

import './NextYearCalendarPage.scss';

const NextYearCalendarPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page
            title={trans('pages.nextYearCalendar.heading')}
            previousAction={RoutePaths.calendar()}
            className="next-year-calendar-page"
        >
            <Helmet>
                <title>{trans('pages.nextYearCalendar.meta.title')}</title>

                <meta property="og:title" content={trans('pages.nextYearCalendar.meta.title')} />
                <meta name="description" content={trans('pages.nextYearCalendar.meta.description')} />
                <meta property="og:description" content={trans('pages.nextYearCalendar.meta.description')} />
            </Helmet>

            <ConnectedNextYearCalendar className="next-year-calendar-page__content" />
        </Page>
    );
};

export default NextYearCalendarPage;
