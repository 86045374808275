/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconUndo: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg
        viewBoxWidth={100}
        viewBoxHeight={84.2}
        className={className}
    >
        <path d="M72.1,28.4H23.2L41.3,8.6L32,0L0,34.7l32,34.7l9.4-8.6L23.2,41.1h48.9c8.4,0,15.2,6.8,15.2,15.2c0,5.3,0,15.2-25.4,15.2v12.7c25.3,0,38.1-9.4,38.1-27.9C100,40.9,87.5,28.4,72.1,28.4z" />
    </Svg>
);

export default IconUndo;
/* eslint-enable max-len */
