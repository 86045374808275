/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconBold: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={90.25} viewBoxHeight={100} className={className}>
        <path d="M86.4,26.21c0,9.69-4.71,16.57-14.13,20.64,6.06,2.26,10.57,5.36,13.54,9.32,2.96,3.96,4.45,8.84,4.45,14.63,0,19.47-14.6,29.2-43.8,29.2H0v-16.99H12.34V16.99H0V0H46.45c13.49,0,23.52,2.17,30.09,6.5,6.57,4.34,9.85,10.91,9.85,19.71Zm-25.95,1.33c0-3.89-1.41-6.62-4.21-8.2-2.81-1.57-6.76-2.36-11.84-2.36h-6.1v22.56h8.03c4.73,0,8.27-.91,10.62-2.72,2.34-1.81,3.52-4.91,3.52-9.29Zm3.12,42.53c0-4.87-1.41-8.36-4.21-10.48-2.81-2.12-7.64-3.19-14.5-3.19h-6.57v26.61h10.22c4.6,0,8.26-1.04,10.98-3.12,2.72-2.08,4.08-5.35,4.08-9.82Z" />
    </Svg>
);

export default IconBold;
/* eslint-enable max-len */
