import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { ErrorLabel } from '../../components';
import { RaceInfoCard, TableSkeletons, TabsNavigation } from '../../compositions';
import { QualifyingResult } from '../../entities/QualifyingResult/QualifyingResult';
import { RaceResult } from '../../entities/RaceResult/RaceResult';
import { RaceWeekend } from '../../entities/RaceWeekend/RaceWeekend';
import { SprintQualifyingResult } from '../../entities/SprintQualifyingResult/SprintQualifyingResult';
import { SprintRaceResult } from '../../entities/SprintRaceResult/SprintRaceResult';
import { Error } from '../../helpers/japi/types/Error';
import { RouteParams, RoutePaths } from '../../Routes';
import {
    QualifyingResultTable,
    RaceResultTable,
    SprintQualifyingResultTable,
    SprintRaceResultTable,
} from '..';

import './RaceWeekendInfo.scss';

enum RaceWeekendRouteTab {
    race = 'race',
    sprintRace = 'sprint-race',
    qualifying = 'qualifying',
    sprintQualifying = 'sprint-qualifying',
}

interface RaceWeekendParams extends RouteParams {
    round?: string;
    tab?: RaceWeekendRouteTab;
}

interface RaceWeekendInfoProps {
    raceResultIsLoading: boolean;
    sprintRaceResultIsLoading: boolean;
    qualifyingResultIsLoading: boolean;
    sprintQualifyingResultIsLoading: boolean;
    isUpcoming: boolean;
    showRaceTab: boolean;
    showSprintRaceTab: boolean;
    showQualifyingTab: boolean;
    showSprintQualifyingTab: boolean;
    raceResultError?: Error;
    sprintRaceResultError?: Error;
    qualifyingResultError?: Error;
    sprintQualifyingResultError?: Error;
    raceWeekend: RaceWeekend;
    raceResult: RaceResult[];
    sprintRaceResult: SprintRaceResult[];
    qualifyingResult: QualifyingResult[];
    sprintQualifyingResult: SprintQualifyingResult[];
    className?: string;
}

const RaceWeekendInfo: FC<RaceWeekendInfoProps> = ({
    raceResultIsLoading,
    sprintRaceResultIsLoading,
    qualifyingResultIsLoading,
    sprintQualifyingResultIsLoading,
    isUpcoming,
    showRaceTab,
    showSprintRaceTab,
    showQualifyingTab,
    showSprintQualifyingTab,
    raceResultError,
    sprintRaceResultError,
    qualifyingResultError,
    sprintQualifyingResultError,
    raceWeekend,
    raceResult,
    sprintRaceResult,
    qualifyingResult,
    sprintQualifyingResult,
    className = '',
}): ReactElement => {
    const { round, tab = RaceWeekendRouteTab.race } = useParams<RaceWeekendParams>();

    const tabs = [
        ...[(showRaceTab && {
            key: 'race',
            to: RoutePaths.raceWeekend(round, RaceWeekendRouteTab.race),
            label: 'Race',
        })],
        ...[(showQualifyingTab && {
            key: 'qualifying',
            to: RoutePaths.raceWeekend(round, RaceWeekendRouteTab.qualifying),
            label: 'Qualifying',
        })],
        ...[(showSprintRaceTab && {
            key: 'sprint-race',
            to: RoutePaths.raceWeekend(round, RaceWeekendRouteTab.sprintRace),
            label: 'Sprint Race',
        })],
        ...[(showSprintQualifyingTab && {
            key: 'sprint-qualifying',
            to: RoutePaths.raceWeekend(round, RaceWeekendRouteTab.sprintQualifying),
            label: 'Sprint Qualifying',
        })],
    ];

    return (
        <div className={`race-weekend-info ${className}`}>
            <RaceInfoCard isUpcoming={isUpcoming} race={raceWeekend.race} />

            {(showRaceTab || showQualifyingTab || showSprintQualifyingTab) && tabs.length > 0 && (
                <div>
                    <TabsNavigation
                        navigationLabel="Results navigation"
                        navigationItems={tabs}
                        className="race-weekend-info__tabs-navigation"
                    />

                    <div className="race-weekend-info__tab-content">
                        {tab === RaceWeekendRouteTab.race && showRaceTab && (
                            <>
                                {raceResultIsLoading && (
                                    <TableSkeletons amount={10} className="race-weekend-info__skeletons" />
                                )}

                                {!raceResultIsLoading && raceResultError && (
                                    <ErrorLabel text={raceResultError.title || ''} className="race-weekend-info__tab-null-state" />
                                )}

                                {!raceResultIsLoading && !raceResultError && raceResult.length === 0 && (
                                    <div className="race-weekend-info__tab-null-state">No race result yet.</div>
                                )}

                                {!raceResultIsLoading && !raceResultError && raceResult.length > 0 && (
                                    <RaceResultTable raceResult={raceResult} />
                                )}
                            </>
                        )}
                        {tab === RaceWeekendRouteTab.qualifying && showQualifyingTab && (
                            <>
                                {qualifyingResultIsLoading && (
                                    <TableSkeletons amount={10} className="race-weekend-info__skeletons" />
                                )}

                                {!qualifyingResultIsLoading && qualifyingResultError && (
                                    <ErrorLabel text={qualifyingResultError.title || ''} className="race-weekend-info__tab-null-state" />
                                )}

                                {!qualifyingResultIsLoading && !qualifyingResultError && qualifyingResult.length === 0 && (
                                    <div className="race-weekend-info__tab-null-state">No qualifying result yet.</div>
                                )}

                                {!qualifyingResultIsLoading && !qualifyingResultError && qualifyingResult.length > 0 && (
                                    <QualifyingResultTable qualifyingResult={qualifyingResult} />
                                )}
                            </>
                        )}
                        {tab === RaceWeekendRouteTab.sprintRace && showSprintRaceTab && (
                            <>
                                {sprintRaceResultIsLoading && (
                                    <TableSkeletons amount={10} className="race-weekend-info__skeletons" />
                                )}

                                {!sprintRaceResultIsLoading && sprintRaceResultError && (
                                    <ErrorLabel text={sprintRaceResultError.title || ''} className="race-weekend-info__tab-null-state" />
                                )}

                                {!sprintRaceResultIsLoading && !sprintRaceResultError && sprintRaceResult.length === 0 && (
                                    <div className="race-weekend-info__tab-null-state">No sprint race result yet.</div>
                                )}

                                {!sprintRaceResultIsLoading && !sprintRaceResultError && sprintRaceResult.length > 0 && (
                                    <SprintRaceResultTable sprintRaceResult={sprintRaceResult} />
                                )}
                            </>
                        )}
                        {tab === RaceWeekendRouteTab.sprintQualifying && showSprintQualifyingTab && (
                            <>
                                {sprintQualifyingResultIsLoading && (
                                    <TableSkeletons amount={10} className="race-weekend-info__skeletons" />
                                )}

                                {!sprintQualifyingResultIsLoading && sprintQualifyingResultError && (
                                    <ErrorLabel text={sprintQualifyingResultError.title || ''} className="race-weekend-info__tab-null-state" />
                                )}

                                {!sprintQualifyingResultIsLoading && !sprintQualifyingResultError && sprintQualifyingResult.length === 0 && (
                                    <div className="race-weekend-info__tab-null-state">No sprint qualifying result yet.</div>
                                )}

                                {!sprintQualifyingResultIsLoading && !sprintQualifyingResultError && sprintQualifyingResult.length > 0 && (
                                    <SprintQualifyingResultTable sprintQualifyingResult={sprintQualifyingResult} />
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RaceWeekendInfo;
