import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RaceResult } from '../../entities/RaceResult/RaceResult';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type RaceResultState = AsyncReduxState<{
    raceResult: RaceResult[];
}>;

const initialState: RaceResultState = {
    ...initialAsyncReduxState,
    raceResult: [],
};

const raceResultSlice = createSlice({
    name: 'raceResultReducer',
    initialState,
    reducers: {
        resetRaceResultState(): RaceResultState {
            return {
                ...initialState,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): RaceResultState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): RaceResultState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setRaceResult(state, action: PayloadAction<RaceResult[]>): RaceResultState {
            return {
                ...state,
                raceResult: action.payload,
            };
        },
    },
});

export const {
    resetRaceResultState,
    setIsLoading,
    setError,
    setRaceResult,
} = raceResultSlice.actions;

export default raceResultSlice.reducer;
