import { FC, ReactElement } from 'react';

import { Settings } from '../../containers';
import { setDarkModeIsActive, setPwaInstallEvent, setPwaIsInstalled } from '../../redux/app/appReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedSettingsProps {
    className?: string;
}

const ConnectedSettings: FC<ConnectedSettingsProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const darkModeIsActive = useTypedSelector(state => state.appReducer.darkModeIsActive);
    const pwaIsInstalled = useTypedSelector(state => state.appReducer.pwaIsInstalled);
    const pwaInstallEvent = useTypedSelector(state => state.appReducer.pwaInstallEvent);

    const handleToggleDarkMode = (isChecked: boolean): void => {
        dispatch(setDarkModeIsActive(isChecked));
    };

    const handleInstallPwa = async (): Promise<void> => {
        if (!pwaInstallEvent) return;

        await pwaInstallEvent.prompt();
        const result = await pwaInstallEvent.userChoice;

        dispatch(setPwaIsInstalled(result.outcome === 'accepted'));
        dispatch(setPwaInstallEvent(undefined));
    };

    return (
        <Settings
            darkModeIsActive={darkModeIsActive}
            pwaIsInstalled={pwaIsInstalled}
            pwaInstallEvent={pwaInstallEvent}
            onToggleDarkMode={handleToggleDarkMode}
            onInstallPwa={handleInstallPwa}
            className={className}
        />
    );
};

export default ConnectedSettings;
