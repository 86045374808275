import { defaultRace, Race, RaceResource } from '../Race/Race';

export interface RaceWeekendResource {
    MRData: {
        RaceTable: {
            season: string;
            Races: RaceResource[];
        };
    };
}

export interface RaceWeekend {
    season: number;
    race: Race;
}

export const defaultRaceWeekend = (): RaceWeekend => ({
    season: 0,
    race: defaultRace(),
});
