import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QualifyingResult } from '../../entities/QualifyingResult/QualifyingResult';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type QualifyingResultState = AsyncReduxState<{
    qualifyingResult: QualifyingResult[];
}>;

const initialState: QualifyingResultState = {
    ...initialAsyncReduxState,
    qualifyingResult: [],
};

const qualifyingResultSlice = createSlice({
    name: 'qualifyingResultReducer',
    initialState,
    reducers: {
        resetQualifyingResultState(): QualifyingResultState {
            return {
                ...initialState,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): QualifyingResultState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): QualifyingResultState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setQualifiyingResult(state, action: PayloadAction<QualifyingResult[]>): QualifyingResultState {
            return {
                ...state,
                qualifyingResult: action.payload,
            };
        },
    },
});

export const {
    resetQualifyingResultState,
    setIsLoading,
    setError,
    setQualifiyingResult,
} = qualifyingResultSlice.actions;

export default qualifyingResultSlice.reducer;
