import { isSSR } from '../../helpers';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import { isDOMException } from '../../helpers/error';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FormOption } from '../../types';
import { FetchCollectionResult, FetchResultType, rejectFetchError } from '../FetchResult';
import { PublicApisCategoriesResource, PublicApisEntriesResource, PublicApisEntry } from './PublicApis';
import { transformPublicApisCategoryToFormOption, transformToPublicApisEntry } from './PublicApisTransformers';

let categoriesAbortController = new AbortController();
let entriesAbortController = new AbortController();

export const getPublicApisCategoriesApiCall = async (): Promise<FetchCollectionResult<FormOption[], string>> => {
    if (!isSSR) {
        categoriesAbortController.abort();
        categoriesAbortController = new AbortController();
    }

    try {
        const url = generateApiUrl({
            endpoint: '/public/categories',
        });

        const response = await authorizedFetch(url, {
            signal: categoriesAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getPublicApisCategoriesApiCall', response);
        }

        const data: PublicApisCategoriesResource = await response.json();
        const categories = data.categories.map(transformPublicApisCategoryToFormOption);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: categories,
        };
    } catch (error: unknown) {
        console.error('[getPublicApisCategoriesApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getPublicApisEntriesApiCall = async (category?: string): Promise<FetchCollectionResult<PublicApisEntry[], string>> => {
    const resultLimit = 36;

    if (!isSSR) {
        entriesAbortController.abort();
        entriesAbortController = new AbortController();
    }

    try {
        const url = generateApiUrl({
            endpoint: '/public/entries',
            queryParams: category ? { category } : undefined,
        });

        const response = await authorizedFetch(url, {
            signal: entriesAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getPublicApisEntriesApiCall', response);
        }

        const data: PublicApisEntriesResource = await response.json();
        const entries = data.entries
            .slice(0, resultLimit)
            .map(transformToPublicApisEntry);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: entries,
        };
    } catch (error: unknown) {
        console.error('[getPublicApisEntriesApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
