/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconCross: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <rect width="82.5" height="13.2" x="8.7" y="43.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.5394 49.8625)" />
        <rect width="13.2" height="82.5" x="44" y="8.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.3323 50.3623)" />
    </Svg>
);

export default IconCross;
/* eslint-enable max-len */
