import { transformToCountryEnum } from '../Country/CountryTransformers';
import { RaceLocation, RaceLocationResource } from './RaceLocation';

export const transformToRaceLocation = (
    constructorResource: RaceLocationResource,
): RaceLocation => ({
    country: constructorResource.country,
    countryAbbreviation: transformToCountryEnum(constructorResource.country),
    locality: constructorResource.locality,
    lat: constructorResource.lat,
    long: constructorResource.long,
});
