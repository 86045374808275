import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import {
    ConstructorStandings,
    ConstructorStandingsResource,
    Standing,
    StandingsResource,
} from './ConstructorStandings';

export const transformToStanding = (
    standingsResource: StandingsResource,
): Standing => ({
    position: Number(standingsResource.position),
    points: Number(standingsResource.points),
    constructor: transformToConstructor(standingsResource.Constructor),
});

export const transformToConstructorStandings = (
    constructorStandingsResource: ConstructorStandingsResource,
): ConstructorStandings => {
    const standingsTabelResource = constructorStandingsResource.MRData.StandingsTable.StandingsLists[0];

    return ({
        season: Number(constructorStandingsResource.MRData.StandingsTable.season),
        standings: standingsTabelResource.ConstructorStandings.map(transformToStanding),
    });
};
