import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getSprintQualifyingResultApiCall } from '../../entities/SprintQualifyingResult/SprintQualifyingResultService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setSprintQualifiyingResult } from './sprintQualifyingResultReducer';

export const fetchSprintQualifyingResult = (round: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const sprintQualifyingResultResponse = await getSprintQualifyingResultApiCall(round);

        if (!isFetchCollectionResultSuccessful(sprintQualifyingResultResponse)) {
            if (sprintQualifyingResultResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: sprintQualifyingResultResponse.status,
                title: sprintQualifyingResultResponse.error,
            }));

            return;
        }

        dispatch(setSprintQualifiyingResult(sprintQualifyingResultResponse.data));
    } catch (error) {
        console.error('[fetchSprintQualifyingResult]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
