import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { Page } from '../../components';
import { ConnectedConstructorStandings } from '../../connectors';
import useTrans from '../../hooks/useTrans';

import './ConstructorStandingsPage.scss';

const ConstructorStandingsPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page title={trans('pages.constructorStandings.heading')} className="constructor-standings-page">
            <Helmet>
                <title>{trans('pages.constructorStandings.meta.title')}</title>

                <meta property="og:title" content={trans('pages.constructorStandings.meta.title')} />
                <meta name="description" content={trans('pages.constructorStandings.meta.description')} />
                <meta property="og:description" content={trans('pages.constructorStandings.meta.description')} />
            </Helmet>

            <ConnectedConstructorStandings className="constructor-standings-page__content" />
        </Page>
    );
};

export default ConstructorStandingsPage;
