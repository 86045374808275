import { isSSR } from '../../helpers';
import { isDOMException } from '../../helpers/error';
import trans from '../../helpers/trans';
import { FetchCollectionResult, FetchResultType, rejectFetchError } from '../FetchResult';
import { SprintRaceResult, SprintRaceResultsResource } from './SprintRaceResult';
import { transformToSprintRaceResult } from './SprintRaceResultTransformers';

let sprintRaceResultAbortController = new AbortController();

export const getSprintRaceResultApiCall = async (round: number): Promise<FetchCollectionResult<SprintRaceResult[], string>> => {
    if (!isSSR) {
        sprintRaceResultAbortController.abort();
        sprintRaceResultAbortController = new AbortController();
    }

    try {
        const response = await fetch(`/results/sprintRace/${round}.json`, {
            signal: sprintRaceResultAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getSprintRaceResultApiCall', response);
        }

        const sprintRaceResultsResource: SprintRaceResultsResource = await response.json();
        const sprintRaceResult = sprintRaceResultsResource.sprintRaceResults.length > 0
            ? sprintRaceResultsResource.sprintRaceResults.map(transformToSprintRaceResult)
            : [];

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: sprintRaceResult,
        };
    } catch (error: unknown) {
        console.error('[getSprintRaceResultApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
