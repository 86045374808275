import { Race, RaceResource } from '../Race/Race';

export interface CalendarResource {
    MRData: {
        RaceTable: {
            season: string;
            Races: RaceResource[];
        };
    };
}

export interface Calendar {
    season: number;
    upcomingRound?: number;
    races: Race[];
}

export const defaultCalendar = (): Calendar => ({
    season: new Date().getFullYear(),
    races: [],
});
