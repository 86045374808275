import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultDriverStandings, DriverStandings } from '../../entities/DriverStandings/DriverStandings';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type DriverStandingsState = AsyncReduxState<{
    standings: DriverStandings;
}>;

const initialState: DriverStandingsState = {
    ...initialAsyncReduxState,
    standings: defaultDriverStandings(),
};

const driverStandingsSlice = createSlice({
    name: 'driverStandingsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DriverStandingsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): DriverStandingsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDriverStandings(state, action: PayloadAction<DriverStandings>): DriverStandingsState {
            return {
                ...state,
                standings: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setDriverStandings,
} = driverStandingsSlice.actions;

export default driverStandingsSlice.reducer;
