import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import { transformToDriver } from '../Driver/DriverTransformers';
import { SprintQualifyingResult, SprintQualifyingResultResource } from './SprintQualifyingResult';

export const transformToSprintQualifyingResult = (
    sprintQualifyingResultResource: SprintQualifyingResultResource,
): SprintQualifyingResult => ({
    position: Number(sprintQualifyingResultResource.position),
    driver: transformToDriver(sprintQualifyingResultResource.Driver),
    constructor: transformToConstructor(sprintQualifyingResultResource.Constructor),
    sq1Time: sprintQualifyingResultResource.SQ1,
    sq2Time: sprintQualifyingResultResource.SQ2,
    sq3Time: sprintQualifyingResultResource.SQ3,
});
