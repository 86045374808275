import { FC, ReactElement } from 'react';

import { StandingsListItem } from '../../compositions';
import { DriverStandings } from '../../entities/DriverStandings/DriverStandings';

import './DriverStandingsList.scss';

interface DriverStandingsListProps {
    driverStandings: DriverStandings;
    className?: string;
}

const DriverStandingsList: FC<DriverStandingsListProps> = ({
    driverStandings,
    className = '',
}): ReactElement => (
    <ul className={`driver-standings-list ${className}`}>
        {driverStandings.standings.map(standing => (
            <StandingsListItem
                key={standing.position}
                position={standing.position}
                flag={standing.driver.nationalityAbbreviation}
                title={`${standing.driver.firstName} ${standing.driver.lastName}`}
                subTitle={standing.constructor.name}
                points={standing.points}
                className="driver-standings-list__list-item"
            />
        ))}
    </ul>
);

export default DriverStandingsList;
