/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronDown: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={50} className={className}>
        <polygon points="100,15 50,50 0,15 0,0 50,35 100,0" />
    </Svg>
);

export default IconChevronDown;
/* eslint-enable max-len */
