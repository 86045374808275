import { FC, ReactElement } from 'react';

import { useEffectOnce } from 'react-use';

import { ErrorLabel } from '../../components';
import { CalendarList } from '../../containers';
import { CalendarListSkeletons } from '../../containers/CalendarList/subcomponents';
import { fetchCalendar } from '../../redux/calendar/calendarActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedCalendarProps {
    className?: string;
}

const ConnectedCalendar: FC<ConnectedCalendarProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.calendarReducer.isLoading);
    const error = useTypedSelector(state => state.calendarReducer.error);
    const calendar = useTypedSelector(state => state.calendarReducer.calendar);

    useEffectOnce((): void => {
        dispatch(fetchCalendar());
    });

    return (
        <div className={className}>
            {isLoading && <CalendarListSkeletons />}

            {(!isLoading && error?.title) && <ErrorLabel text={error.title} />}

            {(!isLoading && calendar.races.length > 0) && (
                <CalendarList calendar={calendar} />
            )}
        </div>
    );
};

export default ConnectedCalendar;
