import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PublicApisEntry } from '../../entities/PublicApis/PublicApis';
import { Error } from '../../helpers/japi/types/Error';
import { FormOption } from '../../types';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type PublicApisState = AsyncReduxState<{
    categories: FormOption[];
    entries: PublicApisEntry[];
}>;

const initialState: PublicApisState = {
    ...initialAsyncReduxState,
    categories: [],
    entries: [],
};

const publicApisSlice = createSlice({
    name: 'publicApisReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): PublicApisState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): PublicApisState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCategories(state, action: PayloadAction<FormOption[]>): PublicApisState {
            return {
                ...state,
                categories: action.payload,
            };
        },
        setEntries(state, action: PayloadAction<PublicApisEntry[]>): PublicApisState {
            return {
                ...state,
                entries: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setCategories,
    setEntries,
} = publicApisSlice.actions;

export default publicApisSlice.reducer;
