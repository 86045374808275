import { FC, ReactElement } from 'react';

import { IconButton, Toggle } from '../../compositions';
import { getPwaInstallPromptCanBeDeferred } from '../../helpers/pwa';
import useTrans from '../../hooks/useTrans';
import { BeforeInstallPromptEvent } from '../../types';

import './Settings.scss';

interface SettingsProps {
    darkModeIsActive: boolean;
    pwaIsInstalled: boolean;
    pwaInstallEvent?: BeforeInstallPromptEvent;
    onToggleDarkMode: (isActive: boolean) => void;
    onInstallPwa: () => void;
    className?: string;
}

const Settings: FC<SettingsProps> = ({
    darkModeIsActive,
    pwaIsInstalled,
    pwaInstallEvent,
    onToggleDarkMode,
    onInstallPwa,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const canBeInstalled = getPwaInstallPromptCanBeDeferred();
    const showInstallButton = canBeInstalled && pwaInstallEvent;

    return (
        <div className={`settings ${className}`}>
            {!pwaIsInstalled && (
                <div style={{ display: pwaIsInstalled ? 'block' : 'none' }} className="settings__install-button-wrapper">
                    {showInstallButton && (
                        <IconButton
                            icon="plus"
                            text={trans('containers.menu.installButton')} // @todo: change to settings translation
                            onClick={onInstallPwa}
                            className="settings__install-button"
                        />
                    )}
                </div>
            )}

            <Toggle
                label={trans('containers.menu.darkModeToggle.label')} // @todo: change to settings translation
                checked={darkModeIsActive}
                onChange={onToggleDarkMode}
                className="settings__dark-mode-toggle"
            />

            <section className="settings__section">
                <h5 className="settings__sub-title">Created by</h5>
                <p>Created by Mike Traa. For contact e-mail miket880@gmail.com.</p>
            </section>

            <section className="settings__section">
                <h5 className="settings__sub-title">Google Play Store</h5>
                <p>
                    Enjoying the calendar? Make sure to leave a rating in the&nbsp;
                    <a
                        href="https://play.google.com/store/apps/details?id=info.f1calendar.app"
                        target="_blank"
                        rel="noreferrer"
                        className="settings__link"
                    >
                        Google Play Store
                    </a>
                    !
                </p>
            </section>

            <section className="settings__section">
                <h5 className="settings__sub-title">API</h5>
                <p>
                    The data is fetched using the&nbsp;
                    <a href="https://ergast.com/mrd/" target="_blank" rel="noreferrer" className="settings__link">
                        Ergast API
                    </a>
                    .
                </p>
            </section>

            <section className="settings__section">
                <h5 className="settings__sub-title settings__disclaimer">Disclaimer</h5>
                <p className="settings__disclaimer">
                    Formula Racing Calendar is an unofficial, free fan made app and is NOT affiliated,
                    endorsed or supported by the Formula 1 Companies&apos; in any way. F1 is a trademark of
                    Formula One Licensing B.V. Copyright material used under Fair Use / Fair Comment.
                    No copyright infringement intended.
                </p>
            </section>
        </div>
    );
};

export default Settings;
