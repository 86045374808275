/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconGarage: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={640} viewBoxHeight={512} className={className}>
        <path d="M326.2 36c-4-1.7-8.4-1.7-12.4 0L51.6 146.4C39.7 151.4 32 163 32 175.9V496c0 8.8-7.2 16-16 16s-16-7.2-16-16V175.9c0-25.8 15.4-49 39.2-59L301.4 6.5c11.9-5 25.3-5 37.3 0L600.8 116.9c23.7 10 39.2 33.2 39.2 59V496c0 8.8-7.2 16-16 16s-16-7.2-16-16V175.9c0-12.9-7.7-24.5-19.6-29.5L326.2 36zM96 224c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32V496c0 8.8-7.2 16-16 16s-16-7.2-16-16V352H128V496c0 8.8-7.2 16-16 16s-16-7.2-16-16V224zm32 96H512V224H128v96zm144 96h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
    </Svg>
);

export default IconGarage;
/* eslint-enable max-len */
