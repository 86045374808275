import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import { transformToDriver } from '../Driver/DriverTransformers';
import {
    DriverStandings,
    DriverStandingsResource,
    Standing,
    StandingsResource,
} from './DriverStandings';

export const transformToStanding = (
    standingsResource: StandingsResource,
): Standing => ({
    position: Number(standingsResource.position),
    points: Number(standingsResource.points),
    driver: transformToDriver(standingsResource.Driver),
    constructor: transformToConstructor(standingsResource.Constructors[0]),
});

export const transformToDriverStandings = (
    driverStandingsResource: DriverStandingsResource,
): DriverStandings => {
    const standingsTabelResource = driverStandingsResource.MRData.StandingsTable.StandingsLists[0];

    return ({
        season: Number(standingsTabelResource.season),
        standings: standingsTabelResource.DriverStandings.map(transformToStanding),
    });
};
