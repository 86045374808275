import { addHours } from '../../helpers/date';
import { transformToCircuit } from '../Circuit/CircuitTransformers';
import { transformToSession } from '../Session/SessionTransformers';
import { Race, RaceResource } from './Race';

export const transformToRace = (
    raceResource: RaceResource,
): Race => {
    const combinedDateTime = `${raceResource.date}T${raceResource.time}`;
    const startDateTime = new Date(combinedDateTime);

    return ({
        round: Number(raceResource.round),
        name: raceResource.raceName,
        startDateTime,
        endDateTime: addHours(new Date(combinedDateTime), 2),
        circuit: transformToCircuit(raceResource.Circuit),
        firstPractice: transformToSession(raceResource.FirstPractice),
        secondPractice: transformToSession(raceResource.SecondPractice),
        thirdPractice: raceResource.ThirdPractice
            ? transformToSession(raceResource.ThirdPractice)
            : undefined,
        qualifying: transformToSession(raceResource.Qualifying),
        sprint: raceResource.Sprint
            ? transformToSession(raceResource.Sprint)
            : undefined,
    });
};

