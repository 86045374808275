import { getDriverStandingsApiCall } from '../../entities/DriverStandings/DriverStandingsService';
import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { setDriverStandings, setError, setIsLoading } from './driverStandingsReducer';

export const fetchDriverStandings = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const driverStandingsResponse = await getDriverStandingsApiCall();

        if (!isFetchCollectionResultSuccessful(driverStandingsResponse)) {
            if (driverStandingsResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: driverStandingsResponse.status,
                title: driverStandingsResponse.error,
            }));

            return;
        }

        dispatch(setDriverStandings(driverStandingsResponse.data));
    } catch (error) {
        console.error('[fetchDriverStandings]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
