import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getRaceWeekendApiCall } from '../../entities/RaceWeekend/RaceWeekendService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setRaceWeekend } from './raceWeekendReducer';

export const fetchRaceWeekend = (round: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const raceResponse = await getRaceWeekendApiCall(round);

        if (!isFetchCollectionResultSuccessful(raceResponse)) {
            if (raceResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: raceResponse.status,
                title: raceResponse.error,
            }));

            return;
        }

        dispatch(setRaceWeekend(raceResponse.data));
    } catch (error) {
        console.error('[fetchRaceWeekend]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
