/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconUnorderedList: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <rect x="40.3" y="14" width="59.7" height="8" />
        <circle cx="13.1" cy="18" r="8" />
        <rect x="40.3" y="48" width="59.7" height="8" />
        <circle cx="13.1" cy="52" r="8" />
        <rect x="40.3" y="78" width="59.7" height="8" />
        <circle cx="13.1" cy="82" r="8" />
    </Svg>
);

export default IconUnorderedList;
/* eslint-enable max-len */
