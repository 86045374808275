import { FC, ReactElement } from 'react';

import { StandingsListItem } from '../../compositions';
import { ConstructorStandings } from '../../entities/ConstructorStandings/ConstructorStandings';

import './ConstructorStandingsList.scss';

interface ConstructorStandingsListProps {
    constructorStandings: ConstructorStandings;
    className?: string;
}

const ConstructorStandingsList: FC<ConstructorStandingsListProps> = ({
    constructorStandings,
    className = '',
}): ReactElement => (
    <ul className={`constructor-standings-list ${className}`}>
        {constructorStandings.standings.map(standing => (
            <StandingsListItem
                key={standing.position}
                position={standing.position}
                flag={standing.constructor.nationalityAbbreviation}
                title={standing.constructor.name}
                points={standing.points}
                className="constructor-standings-list__list-item"
            />
        ))}
    </ul>
);

export default ConstructorStandingsList;
