import { transformToRace } from '../Race/RaceTransformers';
import { RaceWeekend, RaceWeekendResource } from './RaceWeekend';

export const transformToRaceWeekend = (
    raceWeekendResource: RaceWeekendResource,
): RaceWeekend => {
    const raceTableResource = raceWeekendResource.MRData.RaceTable;

    return ({
        season: Number(raceTableResource.season),
        race: transformToRace(raceTableResource.Races[0]),
    });
};
