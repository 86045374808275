import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SprintRaceResult } from '../../entities/SprintRaceResult/SprintRaceResult';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type SprintRaceResultState = AsyncReduxState<{
    sprintRaceResult: SprintRaceResult[];
}>;

const initialState: SprintRaceResultState = {
    ...initialAsyncReduxState,
    sprintRaceResult: [],
};

const sprintRaceResultSlice = createSlice({
    name: 'sprintRaceResultReducer',
    initialState,
    reducers: {
        resetSprintRaceResultState(): SprintRaceResultState {
            return {
                ...initialState,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): SprintRaceResultState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): SprintRaceResultState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSprintRaceResult(state, action: PayloadAction<SprintRaceResult[]>): SprintRaceResultState {
            return {
                ...state,
                sprintRaceResult: action.payload,
            };
        },
    },
});

export const {
    resetSprintRaceResultState,
    setIsLoading,
    setError,
    setSprintRaceResult,
} = sprintRaceResultSlice.actions;

export default sprintRaceResultSlice.reducer;
