import { useEffect, useState } from 'react';

interface ScrollDirection {
    isScrollingDown: boolean;
    isPastMenu: boolean;
}

const useScrollDirection = (): ScrollDirection => {
    const [isScrollingDown, setIsScrollingDown] = useState<boolean>(false);
    const [isPastMenu, setIsPastMenu] = useState<boolean>(false);

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const handleScroll = (): void => {
            const currentScrollY = window.scrollY;

            setIsPastMenu(currentScrollY > 32);

            setIsScrollingDown(lastScrollY < currentScrollY);
            lastScrollY = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return { isScrollingDown, isPastMenu };
};

export default useScrollDirection;
