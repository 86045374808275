import { useCallback } from 'react';

import { useLocale } from '../context/LocaleContext';
import { getTranslation, TranslatorFunction } from '../helpers/trans';

const useTrans = (): TranslatorFunction => {
    const { language } = useLocale();

    return useCallback<TranslatorFunction>((...args) => (
        getTranslation(language, ...args)
    ), [language]);
};

export default useTrans;
