export const formatDate = (date: Date): string => (
    date.toLocaleString(undefined, { day: '2-digit', month: 'short' })
);

export const formatDay = (date: Date): string => (
    date.toLocaleString(undefined, { day: '2-digit' })
);

export const formatMonth = (date: Date): string => (
    date.toLocaleString(undefined, { month: 'short' })
);

export const formatTime = (date: Date): string => (
    date.toLocaleString(undefined, { timeStyle: 'short' })
);

export const formatWeekday = (date: Date): string => (
    date.toLocaleString(undefined, { weekday: 'short' })
);

export const addHours = (date: Date, hours: number): Date => {
    const hoursToAdd = hours * 60 * 60 * 1000;
    date.setTime(date.getTime() + hoursToAdd);

    return date;
};

export const getDifferenceInDays = (date: Date, dateToCompare: Date): number => {
    const differenceInTime = dateToCompare.getTime() - date.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
};
