export interface SessionResource {
    date: string;
    time: string;
}

export interface Session {
    startDateTime: Date;
    endDateTime: Date;
}

export const defaultSession = (): Session => ({
    startDateTime: new Date(),
    endDateTime: new Date(),
});
