/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconItalic: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={53.6} viewBoxHeight={100} className={className}>
        <path d="M43.3,11.7l-18,76.5h9.2L31.6,100H0l2.9-11.8h7.8l18.2-76.5h-8.4L23,0h30.5l-2.9,11.7H43.3z" />
    </Svg>
);

export default IconItalic;
/* eslint-enable max-len */
