import { transformToRaceLocation } from '../RaceLocation/RaceLocationTransformers';
import { Circuit, CircuitResource } from './Circuit';

export const transformToCircuit = (
    circuitResource: CircuitResource,
): Circuit => ({
    id: circuitResource.circuitId,
    name: circuitResource.circuitName,
    location: transformToRaceLocation(circuitResource.Location),
});
