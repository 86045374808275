import { NationalityEnum } from './Nationality';

export const transformToNationalityEnum = (nationalityResource: string): NationalityEnum => {
    switch (nationalityResource) {
        case 'Australian':
            return NationalityEnum.AUS;
        case 'Canadian':
            return NationalityEnum.CAN;
        case 'Chinese':
            return NationalityEnum.CHN;
        case 'Danish':
            return NationalityEnum.DEN;
        case 'Spanish':
            return NationalityEnum.ESP;
        case 'Finnish':
            return NationalityEnum.FIN;
        case 'French':
            return NationalityEnum.FRA;
        case 'British':
            return NationalityEnum.GBR;
        case 'German':
            return NationalityEnum.GER;
        case 'Japanese':
            return NationalityEnum.JPN;
        case 'Mexican':
            return NationalityEnum.MEX;
        case 'Monegasque':
            return NationalityEnum.MON;
        case 'Dutch':
            return NationalityEnum.NED;
        case 'Thai':
            return NationalityEnum.THA;
        case 'American':
            return NationalityEnum.USA;
        default:
            return NationalityEnum.NotFound;
    }
};
