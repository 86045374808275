import { FC, ReactElement } from 'react';

import { SprintRaceResult } from '../../entities/SprintRaceResult/SprintRaceResult';

import './SprintRaceResultTable.scss';

interface SprintRaceResultTableProps {
    sprintRaceResult: SprintRaceResult[];
    className?: string;
}

const SprintRaceResultTable: FC<SprintRaceResultTableProps> = ({
    sprintRaceResult,
    className = '',
}): ReactElement => (
    <table className={`sprint-race-result-table ${className}`}>
        <thead className="sprint-race-result-table__head">
            <tr>
                <th>POS</th>
                <th>NO</th>
                <th>Driver</th>
                <th>Car</th>
                <th>Laps</th>
                <th>Time/Retired</th>
                <th>PTS</th>
            </tr>
        </thead>

        <tbody className="sprint-race-result-table__body">
            {sprintRaceResult.map(result => (
                <tr key={result.position} className="sprint-race-result-table__row">
                    <td>{result.position}</td>
                    <td>{result.driver.number}</td>
                    <td>
                        <span className="sprint-race-result-table__driver-name--abbreviation">{result.driver.abbreviation}</span>
                        <span className="sprint-race-result-table__driver-name--full">{`${result.driver.firstName} ${result.driver.lastName}`}</span>
                    </td>
                    <td className="sprint-race-result-table__constructor-name">{result.constructor.name}</td>
                    <td>{result.amountOfLaps}</td>
                    <td>{result.time}</td>
                    <td>{result.points}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default SprintRaceResultTable;
