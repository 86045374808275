import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConstructorStandings, defaultConstructorStandings } from '../../entities/ConstructorStandings/ConstructorStandings';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ConstructorStandingsState = AsyncReduxState<{
    standings: ConstructorStandings;
}>;

const initialState: ConstructorStandingsState = {
    ...initialAsyncReduxState,
    standings: defaultConstructorStandings(),
};

const constructorStandingsSlice = createSlice({
    name: 'constructorStandingsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ConstructorStandingsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): ConstructorStandingsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setConstructorStandings(state, action: PayloadAction<ConstructorStandings>): ConstructorStandingsState {
            return {
                ...state,
                standings: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setConstructorStandings,
} = constructorStandingsSlice.actions;

export default constructorStandingsSlice.reducer;
