import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon } from '../../../../components';
import { NavigationItemProps } from '../../../../Routes';
import { NavLinkClassName } from '../../../../types';

import './TabsNavigationItem.scss';

const TabsNavigationItem: FC<NavigationItemProps> = ({
    label,
    icon,
    className = '',
    ...navigationItemProps
}): ReactElement => {
    const tabLinkClassName: NavLinkClassName = ({ isActive }): string => classNames('tabs-navigation-item__link', {
        'tabs-navigation-item__link--is-active': isActive,
    });

    return (
        <li className={`tabs-navigation-item ${className}`}>
            <NavLink
                {...navigationItemProps}
                className={tabLinkClassName}
            >
                {icon && (
                    <Icon
                        name={icon}
                        className="tabs-navigation-item__link-icon"
                    />
                )}

                {label}
            </NavLink>
        </li>
    );
};

export default TabsNavigationItem;
