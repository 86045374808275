import { isSSR, localStorageExists } from '.';

export enum ColorScheme {
    light = 'light',
    dark = 'dark',
}

const colorSchemeStorageLabel = 'color-scheme';
const colorSchemeAttribute = 'data-color-scheme';
const defaultColorScheme = ColorScheme.light;

const storeColorScheme = (colorScheme: ColorScheme): void => {
    if (!isSSR && localStorageExists) {
        localStorage.setItem(colorSchemeStorageLabel, colorScheme);
    }
};

export const setColorScheme = (colorScheme: ColorScheme): void => {
    if (isSSR) return;

    storeColorScheme(colorScheme);

    document.documentElement.setAttribute(colorSchemeAttribute, colorScheme);
};

export const getStoredColorScheme = (): ColorScheme => {
    if (isSSR || !localStorageExists) return defaultColorScheme;

    const storedColorScheme = localStorage.getItem(colorSchemeStorageLabel) as ColorScheme;

    if (storedColorScheme) return storedColorScheme;

    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const preferredColorScheme = prefersDarkMode ? ColorScheme.dark : ColorScheme.light;

    setColorScheme(preferredColorScheme);

    return preferredColorScheme;
};
