/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '..';

import './Logo.scss';

interface LogoProps {
    className?: string;
}

const Logo: FC<LogoProps> = ({ className = '' }): ReactElement => (
    <Svg
        viewBoxWidth={375}
        viewBoxHeight={375}
        className={`logo ${className}`}
    >
        <path d="M 69.257812 94.800781 L 305.503906 94.800781 L 305.503906 297.648438 L 69.257812 297.648438 Z M 69.257812 94.800781" />
        <path d="M 241 77.148438 L 257 77.148438 L 257 121 L 241 121 Z M 241 77.148438" />

        <rect x="-37.5" width="450" fill="#ffffff" y="-37.499999" height="449.999989" />
        <rect x="-37.5" width="450" fill="#ff5757" y="-37.499999" height="449.999989" />
        <path fill="#023047" d="M 138.496094 102.890625 L 235.078125 102.890625 C 235.96875 102.890625 236.691406 102.171875 236.691406 101.277344 L 236.691406 96.433594 C 236.691406 95.542969 235.96875 94.816406 235.078125 94.816406 L 138.496094 94.816406 C 137.601562 94.816406 136.878906 95.542969 136.878906 96.433594 L 136.878906 101.277344 C 136.878906 102.171875 137.601562 102.890625 138.496094 102.890625" />

        <path fill="#023047" d="M 280.59375 289.773438 L 94.402344 289.773438 C 84.976562 289.773438 77.332031 282.132812 77.332031 272.703125 L 77.332031 159.066406 L 297.664062 159.066406 L 297.664062 272.703125 C 297.664062 282.132812 290.019531 289.773438 280.59375 289.773438 Z M 280.59375 94.816406 L 262.535156 94.816406 C 261.640625 94.816406 260.917969 95.539062 260.917969 96.433594 L 260.917969 101.277344 C 260.917969 102.167969 261.640625 102.894531 262.535156 102.894531 L 280.59375 102.894531 C 290.007812 102.894531 297.664062 110.546875 297.664062 119.980469 L 297.664062 150.988281 L 77.332031 150.988281 L 77.332031 119.980469 C 77.332031 110.546875 84.988281 102.894531 94.402344 102.894531 L 111.039062 102.894531 C 111.929688 102.894531 112.652344 102.167969 112.652344 101.277344 L 112.652344 96.433594 C 112.652344 95.539062 111.929688 94.816406 111.039062 94.816406 L 94.402344 94.816406 C 80.546875 94.816406 69.257812 106.105469 69.257812 119.980469 L 69.257812 272.703125 C 69.257812 286.535156 80.574219 297.851562 94.402344 297.851562 L 280.59375 297.851562 C 294.421875 297.851562 305.738281 286.535156 305.738281 272.703125 L 305.738281 119.980469 C 305.738281 106.105469 294.449219 94.816406 280.59375 94.816406" />
        <path fill="#023047" d="M 124.765625 122.550781 C 128.773438 122.550781 132.035156 119.300781 132.035156 115.28125 L 132.035156 86.417969 C 132.035156 82.398438 128.773438 79.152344 124.765625 79.152344 C 120.746094 79.152344 117.5 82.398438 117.5 86.417969 L 117.5 115.28125 C 117.5 119.300781 120.746094 122.550781 124.765625 122.550781" />
        <path fill="#023047" d="M 248.804688 120.5625 C 252.8125 120.5625 256.074219 117.296875 256.074219 113.292969 L 256.074219 84.414062 C 256.074219 80.410156 252.8125 77.148438 248.804688 77.148438 C 244.785156 77.148438 241.539062 80.410156 241.539062 84.414062 L 241.539062 113.292969 C 241.539062 117.296875 244.785156 120.5625 248.804688 120.5625" />

        {/* F: start */}
        <g fill="#d32f23" fillOpacity="0.4196">
            <g transform="translate(120.686562, 269.709957)">
                <g>
                    <path d="M 54.125 -70.921875 C 54.039062 -70.515625 53.859375 -70.171875 53.578125 -69.890625 C 53.296875 -69.617188 52.957031 -69.484375 52.5625 -69.484375 L 28.3125 -69.484375 C 27.914062 -69.484375 27.71875 -69.28125 27.71875 -68.875 L 25.3125 -49.921875 C 25.3125 -49.515625 25.472656 -49.3125 25.796875 -49.3125 L 39.71875 -49.3125 C 40.113281 -49.3125 40.429688 -49.171875 40.671875 -48.890625 C 40.921875 -48.617188 41.003906 -48.28125 40.921875 -47.875 L 39.484375 -36.234375 C 39.398438 -35.835938 39.21875 -35.5 38.9375 -35.21875 C 38.65625 -34.9375 38.316406 -34.796875 37.921875 -34.796875 L 24.125 -34.796875 C 23.644531 -34.796875 23.40625 -34.597656 23.40625 -34.203125 L 19.4375 -1.4375 C 19.351562 -1.039062 19.148438 -0.703125 18.828125 -0.421875 C 18.515625 -0.140625 18.160156 0 17.765625 0 L 4.203125 0 C 3.796875 0 3.472656 -0.140625 3.234375 -0.421875 C 2.992188 -0.703125 2.914062 -1.039062 3 -1.4375 L 12.953125 -82.5625 C 13.035156 -82.957031 13.21875 -83.296875 13.5 -83.578125 C 13.78125 -83.859375 14.117188 -84 14.515625 -84 L 54.359375 -84 C 54.753906 -84 55.070312 -83.859375 55.3125 -83.578125 C 55.5625 -83.296875 55.644531 -82.957031 55.5625 -82.5625 Z M 54.125 -70.921875" />
                </g>
            </g>
        </g>
        <g fill="#023047">
            <g transform="translate(117.119468, 266.498128)">
                <g>
                    <path d="M 54.125 -70.921875 C 54.039062 -70.515625 53.859375 -70.171875 53.578125 -69.890625 C 53.296875 -69.617188 52.957031 -69.484375 52.5625 -69.484375 L 28.3125 -69.484375 C 27.914062 -69.484375 27.71875 -69.28125 27.71875 -68.875 L 25.3125 -49.921875 C 25.3125 -49.515625 25.472656 -49.3125 25.796875 -49.3125 L 39.71875 -49.3125 C 40.113281 -49.3125 40.429688 -49.171875 40.671875 -48.890625 C 40.921875 -48.617188 41.003906 -48.28125 40.921875 -47.875 L 39.484375 -36.234375 C 39.398438 -35.835938 39.21875 -35.5 38.9375 -35.21875 C 38.65625 -34.9375 38.316406 -34.796875 37.921875 -34.796875 L 24.125 -34.796875 C 23.644531 -34.796875 23.40625 -34.597656 23.40625 -34.203125 L 19.4375 -1.4375 C 19.351562 -1.039062 19.148438 -0.703125 18.828125 -0.421875 C 18.515625 -0.140625 18.160156 0 17.765625 0 L 4.203125 0 C 3.796875 0 3.472656 -0.140625 3.234375 -0.421875 C 2.992188 -0.703125 2.914062 -1.039062 3 -1.4375 L 12.953125 -82.5625 C 13.035156 -82.957031 13.21875 -83.296875 13.5 -83.578125 C 13.78125 -83.859375 14.117188 -84 14.515625 -84 L 54.359375 -84 C 54.753906 -84 55.070312 -83.859375 55.3125 -83.578125 C 55.5625 -83.296875 55.644531 -82.957031 55.5625 -82.5625 Z M 54.125 -70.921875" />
                </g>
            </g>
        </g>
        {/* F: end */}

        {/* 1: start */}
        <g fill="#d32f23" fillOpacity="0.4196">
            <g transform="translate(169.647496, 269.709957)">
                <g>
                    <path d="M 19.5625 -83.640625 C 20.195312 -83.878906 20.796875 -84 21.359375 -84 L 35.40625 -84 C 36.28125 -84 36.71875 -83.519531 36.71875 -82.5625 L 26.765625 -1.4375 C 26.679688 -1.039062 26.5 -0.703125 26.21875 -0.421875 C 25.9375 -0.140625 25.597656 0 25.203125 0 L 11.515625 0 C 11.117188 0 10.800781 -0.140625 10.5625 -0.421875 C 10.320312 -0.703125 10.238281 -1.039062 10.3125 -1.4375 L 18.359375 -66.953125 C 18.359375 -67.273438 18.195312 -67.4375 17.875 -67.4375 L 8.765625 -66.359375 L 8.515625 -66.359375 C 7.710938 -66.359375 7.3125 -66.800781 7.3125 -67.6875 L 8.28125 -78 C 8.363281 -78.800781 8.800781 -79.363281 9.59375 -79.6875 Z M 19.5625 -83.640625" />
                </g>
            </g>
        </g>
        <g fill="#ffffff">
            <g transform="translate(166.080403, 266.498128)">
                <g>
                    <path d="M 19.5625 -83.640625 C 20.195312 -83.878906 20.796875 -84 21.359375 -84 L 35.40625 -84 C 36.28125 -84 36.71875 -83.519531 36.71875 -82.5625 L 26.765625 -1.4375 C 26.679688 -1.039062 26.5 -0.703125 26.21875 -0.421875 C 25.9375 -0.140625 25.597656 0 25.203125 0 L 11.515625 0 C 11.117188 0 10.800781 -0.140625 10.5625 -0.421875 C 10.320312 -0.703125 10.238281 -1.039062 10.3125 -1.4375 L 18.359375 -66.953125 C 18.359375 -67.273438 18.195312 -67.4375 17.875 -67.4375 L 8.765625 -66.359375 L 8.515625 -66.359375 C 7.710938 -66.359375 7.3125 -66.800781 7.3125 -67.6875 L 8.28125 -78 C 8.363281 -78.800781 8.800781 -79.363281 9.59375 -79.6875 Z M 19.5625 -83.640625" />
                </g>
            </g>
        </g>
        {/* 1: end */}

        {/* C: start */}
        <g fill="#d32f23" fillOpacity="0.4196">
            <g transform="translate(202.7764, 269.709957)">
                <g>
                    <path d="M 24.71875 0.953125 C 18.394531 0.953125 13.394531 -0.820312 9.71875 -4.375 C 6.039062 -7.9375 4.203125 -12.757812 4.203125 -18.84375 C 4.203125 -19.644531 4.28125 -20.925781 4.4375 -22.6875 L 9.234375 -61.5625 C 10.035156 -68.675781 12.816406 -74.351562 17.578125 -78.59375 C 22.335938 -82.832031 28.238281 -84.953125 35.28125 -84.953125 C 41.675781 -84.953125 46.710938 -83.171875 50.390625 -79.609375 C 54.078125 -76.054688 55.921875 -71.238281 55.921875 -65.15625 C 55.921875 -64.351562 55.84375 -63.15625 55.6875 -61.5625 L 55.4375 -60.234375 C 55.351562 -59.835938 55.171875 -59.5 54.890625 -59.21875 C 54.617188 -58.9375 54.28125 -58.796875 53.875 -58.796875 L 40.203125 -58.203125 C 39.316406 -58.203125 38.914062 -58.640625 39 -59.515625 L 39.359375 -62.640625 C 39.441406 -62.960938 39.484375 -63.441406 39.484375 -64.078125 C 39.484375 -65.992188 38.941406 -67.53125 37.859375 -68.6875 C 36.773438 -69.851562 35.316406 -70.4375 33.484375 -70.4375 C 31.484375 -70.4375 29.757812 -69.71875 28.3125 -68.28125 C 26.875 -66.84375 26 -64.960938 25.6875 -62.640625 L 20.640625 -21.234375 C 20.554688 -20.921875 20.515625 -20.484375 20.515625 -19.921875 C 20.515625 -18.003906 21.054688 -16.460938 22.140625 -15.296875 C 23.222656 -14.140625 24.644531 -13.5625 26.40625 -13.5625 C 28.476562 -13.5625 30.234375 -14.257812 31.671875 -15.65625 C 33.117188 -17.0625 34 -18.921875 34.3125 -21.234375 L 34.6875 -24.484375 C 34.757812 -24.878906 34.9375 -25.21875 35.21875 -25.5 C 35.5 -25.78125 35.835938 -25.921875 36.234375 -25.921875 L 49.796875 -25.3125 C 50.203125 -25.3125 50.523438 -25.171875 50.765625 -24.890625 C 51.003906 -24.617188 51.082031 -24.28125 51 -23.875 L 50.875 -22.6875 C 50 -15.476562 47.160156 -9.734375 42.359375 -5.453125 C 37.554688 -1.179688 31.675781 0.953125 24.71875 0.953125 Z M 24.71875 0.953125" />
                </g>
            </g>
        </g>
        <g fill="#023047">
            <g transform="translate(199.209307, 266.498128)">
                <g>
                    <path d="M 24.71875 0.953125 C 18.394531 0.953125 13.394531 -0.820312 9.71875 -4.375 C 6.039062 -7.9375 4.203125 -12.757812 4.203125 -18.84375 C 4.203125 -19.644531 4.28125 -20.925781 4.4375 -22.6875 L 9.234375 -61.5625 C 10.035156 -68.675781 12.816406 -74.351562 17.578125 -78.59375 C 22.335938 -82.832031 28.238281 -84.953125 35.28125 -84.953125 C 41.675781 -84.953125 46.710938 -83.171875 50.390625 -79.609375 C 54.078125 -76.054688 55.921875 -71.238281 55.921875 -65.15625 C 55.921875 -64.351562 55.84375 -63.15625 55.6875 -61.5625 L 55.4375 -60.234375 C 55.351562 -59.835938 55.171875 -59.5 54.890625 -59.21875 C 54.617188 -58.9375 54.28125 -58.796875 53.875 -58.796875 L 40.203125 -58.203125 C 39.316406 -58.203125 38.914062 -58.640625 39 -59.515625 L 39.359375 -62.640625 C 39.441406 -62.960938 39.484375 -63.441406 39.484375 -64.078125 C 39.484375 -65.992188 38.941406 -67.53125 37.859375 -68.6875 C 36.773438 -69.851562 35.316406 -70.4375 33.484375 -70.4375 C 31.484375 -70.4375 29.757812 -69.71875 28.3125 -68.28125 C 26.875 -66.84375 26 -64.960938 25.6875 -62.640625 L 20.640625 -21.234375 C 20.554688 -20.921875 20.515625 -20.484375 20.515625 -19.921875 C 20.515625 -18.003906 21.054688 -16.460938 22.140625 -15.296875 C 23.222656 -14.140625 24.644531 -13.5625 26.40625 -13.5625 C 28.476562 -13.5625 30.234375 -14.257812 31.671875 -15.65625 C 33.117188 -17.0625 34 -18.921875 34.3125 -21.234375 L 34.6875 -24.484375 C 34.757812 -24.878906 34.9375 -25.21875 35.21875 -25.5 C 35.5 -25.78125 35.835938 -25.921875 36.234375 -25.921875 L 49.796875 -25.3125 C 50.203125 -25.3125 50.523438 -25.171875 50.765625 -24.890625 C 51.003906 -24.617188 51.082031 -24.28125 51 -23.875 L 50.875 -22.6875 C 50 -15.476562 47.160156 -9.734375 42.359375 -5.453125 C 37.554688 -1.179688 31.675781 0.953125 24.71875 0.953125 Z M 24.71875 0.953125" />
                </g>
            </g>
        </g>
        {/* C: end */}
    </Svg>
);

export default Logo;
/* eslint-enable max-len */
