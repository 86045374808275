import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getRaceResultApiCall } from '../../entities/RaceResult/RaceResultService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setRaceResult } from './raceResultReducer';

export const fetchRaceResult = (round: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const raceResultResponse = await getRaceResultApiCall(round);

        if (!isFetchCollectionResultSuccessful(raceResultResponse)) {
            if (raceResultResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: raceResultResponse.status,
                title: raceResultResponse.error,
            }));

            return;
        }

        dispatch(setRaceResult(raceResultResponse.data));
    } catch (error) {
        console.error('[fetchRaceResult]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
