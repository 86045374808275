import { transformToCountryEnum } from '../Country/CountryTransformers';
import { Constructor, ConstructorResource } from './Constructor';

export const transformToConstructor = (
    constructorResource: ConstructorResource,
): Constructor => ({
    id: constructorResource.constructorId,
    name: constructorResource.name,
    nationalityAbbreviation: transformToCountryEnum(constructorResource.nationality),
});
