import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getQualifyingResultApiCall } from '../../entities/QualifyingResult/QualifyingResultService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setQualifiyingResult } from './qualifyingResultReducer';

export const fetchQualifyingResult = (round: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const qualifyingResultResponse = await getQualifyingResultApiCall(round);

        if (!isFetchCollectionResultSuccessful(qualifyingResultResponse)) {
            if (qualifyingResultResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: qualifyingResultResponse.status,
                title: qualifyingResultResponse.error,
            }));

            return;
        }

        dispatch(setQualifiyingResult(qualifyingResultResponse.data));
    } catch (error) {
        console.error('[fetchQualifyingResult]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
