import { transformToCircuit } from '../Circuit/CircuitTransformers';
import {
    NextYearCalendar,
    NextYearCalendarResource,
    NextYearRace,
    NextYearRaceResource,
} from './NextYearCalendar';

const transformToNextYearRace = (
    nextYearRaceResource: NextYearRaceResource,
): NextYearRace => ({
    round: Number(nextYearRaceResource.round),
    name: nextYearRaceResource.raceName,
    startDate: new Date(nextYearRaceResource.startDate),
    endDate: new Date(nextYearRaceResource.endDate),
    circuit: transformToCircuit(nextYearRaceResource.Circuit),
});

export const transformToNextYearCalendar = (
    nextYearCalendarResource: NextYearCalendarResource,
): NextYearCalendar => {
    const races = nextYearCalendarResource.races.map(transformToNextYearRace);

    return ({
        season: Number(nextYearCalendarResource.season),
        races,
    });
};
