import { addHours } from '../../helpers/date';
import { Session, SessionResource } from './Session';

export const transformToSession = (
    sessionResource: SessionResource,
): Session => {
    const combinedDateTime = `${sessionResource.date}T${sessionResource.time}`;

    return ({
        startDateTime: new Date(combinedDateTime),
        endDateTime: addHours(new Date(combinedDateTime), 1),
    });
};

