import { isSSR } from '../../helpers';
import { isDOMException } from '../../helpers/error';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType, rejectFetchError } from '../FetchResult';
import { QualifyingResult, QualifyingResultResource } from './QualifyingResult';
import { transformToQualifyingResult } from './QualifyingResultTransformers';

let qualifyingResultAbortController = new AbortController();

export const getQualifyingResultApiCall = async (round: number): Promise<FetchCollectionResult<QualifyingResult[], string>> => {
    if (!isSSR) {
        qualifyingResultAbortController.abort();
        qualifyingResultAbortController = new AbortController();
    }

    try {
        const url = generateApiUrl({
            endpoint: `/2024/${round}/qualifying.json`,
        });

        const response = await fetch(url, {
            signal: qualifyingResultAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getQualifyingResultApiCall', response);
        }

        const qualifyingResultResource: QualifyingResultResource = await response.json();
        const qualifyingResult = qualifyingResultResource.MRData.RaceTable.Races.length > 0
            ? qualifyingResultResource.MRData.RaceTable.Races[0].QualifyingResults.map(transformToQualifyingResult)
            : [];

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: qualifyingResult,
        };
    } catch (error: unknown) {
        console.error('[getQualifyingResultApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
