import { CountryEnum } from './Country';

export const transformToCountryEnum = (countryResource: string): CountryEnum | undefined => {
    switch (countryResource) {
        case 'Australia':
        case 'Australian':
            return CountryEnum.AU;
        case 'Austria':
        case 'Austrian':
            return CountryEnum.AT;
        case 'Azerbaijan':
            return CountryEnum.AZ;
        case 'Bahrain':
            return CountryEnum.BH;
        case 'Belgium':
            return CountryEnum.BE;
        case 'Brazil':
            return CountryEnum.BR;
        case 'Canada':
        case 'Canadian':
            return CountryEnum.CA;
        case 'China':
        case 'Chinese':
            return CountryEnum.CN;
        case 'Hungary':
            return CountryEnum.HU;
        case 'Danish':
            return CountryEnum.DK;
        case 'Finnish':
            return CountryEnum.FI;
        case 'French':
            return CountryEnum.FR;
        case 'German':
            return CountryEnum.DE;
        case 'Italy':
        case 'Italian':
            return CountryEnum.IT;
        case 'Japan':
        case 'Japanese':
            return CountryEnum.JP;
        case 'Mexico':
        case 'Mexican':
            return CountryEnum.MX;
        case 'Monaco':
        case 'Monegasque':
            return CountryEnum.MC;
        case 'Netherlands':
        case 'Dutch':
            return CountryEnum.NL;
        case 'Qatar':
            return CountryEnum.QA;
        case 'Saudi Arabia':
            return CountryEnum.SA;
        case 'Singapore':
            return CountryEnum.SG;
        case 'Spain':
        case 'Spanish':
            return CountryEnum.ES;
        case 'Swiss':
            return CountryEnum.CH;
        case 'Thai':
            return CountryEnum.TH;
        case 'UK':
        case 'British':
            return CountryEnum.GB;
        case 'United States':
        case 'USA':
        case 'American':
            return CountryEnum.US;
        case 'UAE':
            return CountryEnum.AE;
        default:
            return undefined;
    }
};
