import { FC, ReactElement } from 'react';

import { QualifyingResult } from '../../entities/QualifyingResult/QualifyingResult';

import './QualifyingResultTable.scss';

interface QualifyingResultTableProps {
    qualifyingResult: QualifyingResult[];
    className?: string;
}

const QualifyingResultTable: FC<QualifyingResultTableProps> = ({
    qualifyingResult,
    className = '',
}): ReactElement => (
    <table className={`qualifying-result-table ${className}`}>
        <thead className="qualifying-result-table__head">
            <tr>
                <th>POS</th>
                <th>NO</th>
                <th>Driver</th>
                <th>Car</th>
                <th>Q1</th>
                <th>Q2</th>
                <th>Q3</th>
            </tr>
        </thead>

        <tbody className="qualifying-result-table__body">
            {qualifyingResult.map(result => (
                <tr key={result.position} className="qualifying-result-table__row">
                    <td>{result.position}</td>
                    <td>{result.driver.number}</td>
                    <td>
                        <span className="qualifying-result-table__driver-name--abbreviation">{result.driver.abbreviation}</span>
                        <span className="qualifying-result-table__driver-name--full">{`${result.driver.firstName} ${result.driver.lastName}`}</span>
                    </td>
                    <td className="qualifying-result-table__constructor-name">{result.constructor.name}</td>
                    <td>{result.q1Time}</td>
                    <td>{result.q2Time || ''}</td>
                    <td>{result.q3Time || ''}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default QualifyingResultTable;
