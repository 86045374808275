import { isSSR } from '.';
import { urlBase64ToUint8Array } from './url';

export interface PushNotificationOptions extends NotificationOptions {
    delay?: number;
}

export const getPwaInstallPromptCanBeDeferred = (): boolean => !isSSR && 'BeforeInstallPromptEvent' in window;
export const getPwaSupportsAppBadge = (): boolean => !isSSR && 'setAppBadge' in navigator;

export const getPwaIsInstalled = (): boolean => {
    if (isSSR || !window.matchMedia) return false;

    return window.matchMedia('(display-mode: standalone)').matches;
};

export const registerServiceWorker = async (options?: RegistrationOptions): Promise<void> => {
    if (!navigator.serviceWorker) return;

    await navigator.serviceWorker.register('/service-worker.js', options);

    // Purge expired cache files that follow the Cache first strategy
    // Configure in service-worker, or remove entirely if cache should have no expiration date
    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(JSON.stringify({
            action: 'purge-cache',
        }));
    }
};

export const getServiceWorkerRegistration = async (): Promise<ServiceWorkerRegistration | undefined> => {
    if (!navigator.serviceWorker) {
        console.warn('Service worker is not available');
        return undefined;
    }

    return navigator.serviceWorker.ready;
};

export const getPushSubscription = async (): Promise<PushSubscription | undefined> => {
    const registration = await getServiceWorkerRegistration();
    const pushManager = registration?.pushManager;

    if (!pushManager) {
        console.warn('Push manager is not available');
        return undefined;
    }

    const subscription = await pushManager.getSubscription();
    if (!subscription) {
        console.warn('No active Push subscription found for service worker');
        return undefined;
    }

    return subscription;
};

export const subscribeToPush = async (title: string, options: PushNotificationOptions): Promise<void> => {
    const permission = await Notification.requestPermission();

    if (permission !== 'granted') return;

    const publicVapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

    if (!publicVapidKey) {
        console.warn('No public VAPID key given.');
        return;
    }

    // Retrieve active service worker
    const registration = await getServiceWorkerRegistration();
    const pushManager = registration?.pushManager;

    if (!pushManager) return;

    // Create Push subscription and set to active service worker
    const subscription = await pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    });

    try {
        // Set Push subscription to database
        await fetch('/notification/subscribe', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                subscription,
                title,
                options,
            }),
        });
    } catch (error) {
        console.error('[subscribeToPush]', error);
    }
};

export const unsubscribeFromPush = async (title: string, options: PushNotificationOptions): Promise<void> => {
    const subscription = await getPushSubscription();
    if (!subscription) return;

    try {
        // Remove Push subscription from database on the server
        await fetch('/notification/unsubscribe', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                subscription,
                title,
                options,
            }),
        });

        // Remove Push subscription from service worker
        await subscription.unsubscribe();
    } catch (error) {
        console.error('[unsubscribeFromPush]', error);
    }
};

export const getNotificationPermission = (): NotificationPermission => {
    if (isSSR) return 'default';

    return Notification.permission;
};

export const sendServerNotification = async (title: string, options: PushNotificationOptions): Promise<void> => {
    const subscription = await getPushSubscription();
    if (!subscription) return;

    await fetch('/notification/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            subscription,
            title,
            options,
        }),
    });
};

export const sendClientNotification = async (title: string, options: PushNotificationOptions): Promise<void> => {
    const registration = await getServiceWorkerRegistration();
    if (!registration) return;

    const subscription = await getPushSubscription();
    if (!subscription) return;

    const { delay = 0, ...notificationOptions } = options;

    setTimeout((): void => {
        registration.showNotification(title, notificationOptions);
    }, delay);
};

export const setAppBadge = (value: number): void => {
    const isInstalled = getPwaIsInstalled();
    const supportsAppBadge = getPwaSupportsAppBadge();

    if (!isInstalled || !supportsAppBadge) return;

    // @ts-ignore
    navigator.setAppBadge(value);
};

export const clearAppBadge = (): void => {
    const isInstalled = getPwaIsInstalled();
    const supportsAppBadge = getPwaSupportsAppBadge();

    if (!isInstalled || !supportsAppBadge) return;

    // @ts-ignore
    navigator.clearAppBadge();
};
