import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultRaceWeekend, RaceWeekend } from '../../entities/RaceWeekend/RaceWeekend';
import { Error } from '../../helpers/japi/types/Error';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type RaceWeekendState = AsyncReduxState<{
    raceWeekend: RaceWeekend;
}>;

const initialState: RaceWeekendState = {
    ...initialAsyncReduxState,
    raceWeekend: defaultRaceWeekend(),
};

const raceWeekendSlice = createSlice({
    name: 'raceWeekendReducer',
    initialState,
    reducers: {
        resetRaceWeekendState(): RaceWeekendState {
            return {
                ...initialState,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): RaceWeekendState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<Error | undefined>): RaceWeekendState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setRaceWeekend(state, action: PayloadAction<RaceWeekend>): RaceWeekendState {
            return {
                ...state,
                raceWeekend: action.payload,
            };
        },
    },
});

export const {
    resetRaceWeekendState,
    setIsLoading,
    setError,
    setRaceWeekend,
} = raceWeekendSlice.actions;

export default raceWeekendSlice.reducer;
