import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getSprintRaceResultApiCall } from '../../entities/SprintRaceResult/SprintRaceResultService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setSprintRaceResult } from './sprintRaceResultReducer';

export const fetchSprintRaceResult = (round: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const sprintRaceResultResponse = await getSprintRaceResultApiCall(round);

        if (!isFetchCollectionResultSuccessful(sprintRaceResultResponse)) {
            if (sprintRaceResultResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: sprintRaceResultResponse.status,
                title: sprintRaceResultResponse.error,
            }));

            return;
        }

        dispatch(setSprintRaceResult(sprintRaceResultResponse.data));
    } catch (error) {
        console.error('[fetchSprintRaceResult]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
