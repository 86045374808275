import { FC, PropsWithChildren, ReactElement } from 'react';

import './Svg.scss';

interface SvgProps {
    viewBoxWidth: number;
    viewBoxHeight: number;
    className?: string;
}

const Svg: FC<PropsWithChildren<SvgProps>> = ({
    viewBoxWidth,
    viewBoxHeight,
    className = '',
    children,
}): ReactElement => (
    <svg
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        className={`svg ${className}`}
    >
        {children}
    </svg>
);

export default Svg;
