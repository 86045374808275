import { FetchResultType, isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getNextYearCalendarApiCall } from '../../entities/NextYearCalendar/NextYearCalendarService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setNextYearCalendar } from './nextYearCalendarReducer';

export const fetchNextYearCalendar = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const nextYearCalendarResponse = await getNextYearCalendarApiCall();

        if (!isFetchCollectionResultSuccessful(nextYearCalendarResponse)) {
            if (nextYearCalendarResponse.type === FetchResultType.Abort) {
                return;
            }

            dispatch(setError({
                status: nextYearCalendarResponse.status,
                title: nextYearCalendarResponse.error,
            }));

            return;
        }

        dispatch(setNextYearCalendar(nextYearCalendarResponse.data));
    } catch (error) {
        console.error('[fetchNextYearCalendar]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
