/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconSend: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg
        viewBoxWidth={100}
        viewBoxHeight={85.7}
        className={className}
    >
        <path d="M0,85.7l100-42.9L0,0v33.3l71.4,9.5L0,52.4V85.7z" />
    </Svg>
);

export default IconSend;
/* eslint-enable max-len */
