import { FormOption } from '../types';

export enum Locale {
    english = 'English',
    // dutch = 'Nederlands',
}

export enum Direction {
    ltr = 'ltr',
    rtl = 'rtl',
}

export enum Language {
    en = 'en',
    // nl = 'nl',
}

export interface LocaleData {
    name: Locale;
    language: Language;
    direction: Direction;
}

export const locales: Record<Locale, LocaleData> = {
    [Locale.english]: {
        name: Locale.english,
        language: Language.en,
        direction: Direction.ltr,
    },
    // [Locale.dutch]: {
    //     name: Locale.dutch,
    //     language: Language.nl,
    //     direction: Direction.ltr,
    // },
};

export const defaultLocale = locales[Locale.english];

export const languageOptions: FormOption[] = Object.values(locales).map(locale => ({
    label: locale.name,
    value: locale.language,
}));
