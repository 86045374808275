import {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
} from 'react';

import { ColorScheme, getStoredColorScheme, setColorScheme } from '../../helpers/colorScheme';
import { getPwaIsInstalled } from '../../helpers/pwa';
import { setDarkModeIsActive, setPwaInstallEvent, setPwaIsInstalled } from '../../redux/app/appReducer';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { BeforeInstallPromptEvent } from '../../types';

const ConnectedAppWrapper: FC<PropsWithChildren> = ({
    children,
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const darkModeIsActive = useTypedSelector(state => state.appReducer.darkModeIsActive);

    const storeInstallPrompt = (event: Event): void => {
        event.preventDefault();

        dispatch(setPwaInstallEvent(event as BeforeInstallPromptEvent));
    };

    const handleAppInstalled = (): void => {
        dispatch(setPwaIsInstalled(true));
    };

    const setDarkModePreference = (event: MediaQueryList | MediaQueryListEvent): void => {
        const prefersDarkMode = event.matches;
        dispatch(setDarkModeIsActive(prefersDarkMode));
    };

    useEffect((): () => void => {
        const storedColorScheme = getStoredColorScheme();
        const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)');
        const appIsStandAlone = getPwaIsInstalled();

        dispatch(setDarkModeIsActive(storedColorScheme === ColorScheme.dark));
        dispatch(setPwaIsInstalled(appIsStandAlone));

        darkModePreference.addEventListener('change', setDarkModePreference);
        window.addEventListener('beforeinstallprompt', storeInstallPrompt);
        window.addEventListener('appinstalled', handleAppInstalled);

        return (): void => {
            darkModePreference.removeEventListener('change', setDarkModePreference);
            window.removeEventListener('beforeinstallprompt', storeInstallPrompt);
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    useEffect((): void => {
        setColorScheme(darkModeIsActive ? ColorScheme.dark : ColorScheme.light);
    }, [darkModeIsActive]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default ConnectedAppWrapper;
