import { FormOption } from '../types';

export const socketReconnectDelay = 2500;

export const urls = {
    api: process.env.REACT_APP_API_URL || 'https://ergast.com/api/f1',
};

export const defaultFormOption = (): FormOption => ({
    label: '',
    value: '',
});
