import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { NavLink } from 'react-router-dom';

import { RaceInfoCard } from '../../../../compositions';
import { Race } from '../../../../entities/Race/Race';
import { getDifferenceInDays } from '../../../../helpers/date';
import useTrans from '../../../../hooks/useTrans';
import { RoutePaths } from '../../../../Routes';

import './CalendarListItem.scss';

interface CalendarListItemProps extends RefAttributes<HTMLLIElement> {
    race: Race;
    isUpcoming: boolean;
    className?: string;
}

const CalendarListItem: ForwardRefExoticComponent<CalendarListItemProps> = forwardRef(({
    race,
    isUpcoming,
    className = '',
}, ref: Ref<HTMLLIElement>): ReactElement => {
    const trans = useTrans();

    const activeDetailTab = getDifferenceInDays(new Date(), race.qualifying.startDateTime) === 0
        ? 'qualifying'
        : 'race';

    return (
        <li ref={ref} className={`calendar-list-item ${className}`}>
            <NavLink
                to={RoutePaths.raceWeekend(String(race.round), activeDetailTab)}
                title={trans('compositions.calendarListItem.toDetail')}
                className="calendar-list-item__link"
            >
                <RaceInfoCard isUpcoming={isUpcoming} race={race} />
            </NavLink>
        </li>
    );
});

export default CalendarListItem;
