import { getDifferenceInDays } from '../../helpers/date';
import { Race } from '../Race/Race';
import { transformToRace } from '../Race/RaceTransformers';
import { Calendar, CalendarResource } from './Calendar';

const getUpcomingRound = (races: Race[]): number | undefined => {
    let activeRound = 0;

    races.some(race => {
        const dateDifference = getDifferenceInDays(new Date(), race.startDateTime);

        if (dateDifference >= 0) {
            activeRound = race.round;
            return true;
        }

        return false;
    });

    return activeRound;
};

export const transformToCalendar = (
    calendarResource: CalendarResource,
): Calendar => {
    const raceTableResource = calendarResource.MRData.RaceTable;
    const races = raceTableResource.Races.map(transformToRace);

    return ({
        season: Number(raceTableResource.season),
        upcomingRound: getUpcomingRound(races),
        races,
    });
};
