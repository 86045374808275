export enum NationalityEnum {
    AUS = 'AUS',
    CAN = 'CAN',
    CHN = 'CHN',
    DEN = 'DEN',
    ESP = 'ESP',
    FIN = 'FIN',
    FRA = 'FRA',
    GBR = 'GBR',
    GER = 'GER',
    JPN = 'JPN',
    MEX = 'MEX',
    MON = 'MON',
    NED = 'NED',
    THA = 'THA',
    USA = 'USA',
    NotFound = 'Not found',
}
