import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { LinkButton, Page } from '../../components';
import useTrans from '../../hooks/useTrans';
import { RoutePaths } from '../../Routes';

import './NotFoundPage.scss';

const NotFoundPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="not-found-page">
            <Helmet>
                <title>{trans('pages.notFound.meta.title')}</title>
                <meta property="og:title" content={trans('pages.notFound.meta.title')} />
                <meta name="description" content={trans('pages.notFound.meta.description')} />
                <meta property="og:description" content={trans('pages.notFound.meta.description')} />
            </Helmet>

            <h1 className="not-found-page__title">
                {trans('pages.notFound.heading')}
            </h1>

            <p className="not-found-page__description">
                {trans('pages.notFound.description')}
            </p>

            <LinkButton
                to={RoutePaths.home()}
                text={trans('pages.notFound.button.backToHome')}
                className="not-found-page__link-button"
            />
        </Page>
    );
};

export default NotFoundPage;
