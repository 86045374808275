import { defaultLocale, Language } from '../constants/locale';
import enLanguage from '../translation/en.json';
import { localStorageExists } from '.';

type TranslationParams = Record<string, string | number | undefined>;
export type TranslatorFunction = (path: string, params?: TranslationParams) => string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const languages: Record<Language, any> = {
    [Language.en]: enLanguage,
    // [Language.nl]: nlLanguage,
};

export const getTranslation = (language = defaultLocale.language, path = '', params: TranslationParams = {}): string => {
    const selectors = path.split('.');
    const paramKeys = Object.keys(params);

    // Reduce keys to retrieve translation
    const translation = selectors.reduce((currentKey, nextKey) => {
        // Log an error if path can't be resolved, anywhere within translation
        if (!currentKey || !currentKey[nextKey]) {
            console.error(`No translation found for: ${path}`);
            return `{{${path}}}`;
        }

        return currentKey && currentKey[nextKey];
    }, languages[language]);

    if (!params) {
        return translation;
    }

    // Apply given parameters to translation and return outcome
    return paramKeys.reduce((label, param) => {
        const regex = new RegExp(`:${param}:`, 'g');

        return label.replace(regex, params[param]);
    }, translation);
};

const trans: TranslatorFunction = (...args) => {
    let { language } = defaultLocale;

    if (localStorageExists) {
        const storedLocale = localStorage.getItem('locale');

        if (storedLocale) {
            language = JSON.parse(storedLocale).language;
        }
    }

    return getTranslation(language, ...args);
};

export default trans;
