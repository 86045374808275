import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { LinkIconButton } from '../../../../compositions';
import useScrollDirection from '../../../../hooks/useScrollDirection';
import { RoutePaths } from '../../../../Routes';
import Logo from '../../../Logo/Logo';

import './PageHeader.scss';

interface PageHeaderProps {
    title: string;
    previousAction: string;
    className?: string;
}

const PageHeader: FC<PageHeaderProps> = ({
    title,
    previousAction,
    className = '',
}): ReactElement => {
    const { isScrollingDown, isPastMenu } = useScrollDirection();

    const pageHeaderClassNames = classNames('page-header', {
        'page-header--is-scrolling-down': isScrollingDown && isPastMenu,
    }, className);

    return (
        <header className={pageHeaderClassNames}>
            <div className="page-header__title-wrapper">
                {previousAction && (
                    <LinkIconButton
                        hideLabel
                        to={previousAction}
                        icon="arrow-left"
                        text="Back to calendar"
                        className="page-header__previous-button"
                    />
                )}
                <h1 className="page-header__title">{title}</h1>
            </div>

            <div className="page-header__actions">
                <LinkIconButton
                    hideLabel
                    to={RoutePaths.settings()}
                    icon="gear"
                    text="Settings"
                    iconClassName="page-header__settings-button-icon"
                    className="page-header__settings-button"
                />
                <Logo className="page-header__logo" />
            </div>
        </header>
    );
};

export default PageHeader;
