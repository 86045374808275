import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './CalendarListSkeletons.scss';

interface CalendarListSkeletonsProps {
    className?: string;
}

const CalendarListSkeletons: FC<CalendarListSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const listItems = generateIdArray(5);

    return (
        <ul className={`calendar-list-skeletons ${className}`}>
            {listItems.map(item => (
                <li key={item} className="calendar-list-skeletons__list-item">
                    <div className="calendar-list-skeletons__info">
                        <Skeleton className="calendar-list-skeletons__location" />
                        <Skeleton className="calendar-list-skeletons__title" />
                        <Skeleton className="calendar-list-skeletons__time-label" />
                        <Skeleton className="calendar-list-skeletons__time" />
                    </div>

                    <Skeleton className="calendar-list-skeletons__dates" />
                </li>
            ))}
        </ul>
    );
};

export default CalendarListSkeletons;
