/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconArrowDown: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={84.9} viewBoxHeight={100} className={className}>
        <polygon points="37.4,0 37.4,80.9 7.1,50.5 0,57.6 35.4,92.9 42.4,100 49.5,92.9 84.9,57.6 77.8,50.5 47.4,80.9 47.4,0" />
    </Svg>
);

export default IconArrowDown;
/* eslint-enable max-len */
