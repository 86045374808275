import { useCallback } from 'react';

import { Language } from '../constants/locale';
import { useLocale } from '../context/LocaleContext';
import { getTranslation } from '../helpers/trans';

interface DateFormatOptions extends Intl.DateTimeFormatOptions {
    dateStyle?: 'full' | 'long' | 'medium' | 'short';
    timeStyle?: 'full' | 'long' | 'medium' | 'short';
}

type DateFormatFunction = (date?: Date, options?: DateFormatOptions) => string;

const dateLocales: Record<Language, string> = {
    [Language.en]: 'en-GB',
    // [Language.nl]: 'nl-NL',
};

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 31;
const year = day * 365;

const useFormatDate = (): DateFormatFunction => {
    const { language } = useLocale();

    return useCallback<DateFormatFunction>((date, options) => {
        if (!date) return '';

        const dateLocale = dateLocales[language];

        return new Intl.DateTimeFormat(dateLocale, options).format(date);
    }, [language]);
};

export const useFormatRelativeDate = (): DateFormatFunction => {
    const { language } = useLocale();

    return useCallback<DateFormatFunction>(date => {
        if (!date) return '';

        const dateLocale = dateLocales[language];
        const rtf = new Intl.RelativeTimeFormat(dateLocale, { numeric: 'auto' });

        const now = new Date();
        const difference = now.getTime() - date.getTime();

        if (difference < second) {
            return getTranslation(language, 'common.now');
        }

        if (difference < minute) {
            const differenceInSeconds = Math.round(difference / second);
            return rtf.format(-differenceInSeconds, 'second');
        }

        if (difference < hour) {
            const differenceInMinutes = Math.round(difference / minute);
            return rtf.format(-differenceInMinutes, 'minute');
        }

        if (difference < day) {
            const differenceInHours = Math.round(difference / hour);
            return rtf.format(-differenceInHours, 'hour');
        }

        if (difference < week) {
            const differenceInDays = Math.round(difference / day);
            return rtf.format(-differenceInDays, 'day');
        }

        if (difference < month) {
            const differenceInDays = Math.round(difference / week);
            return rtf.format(-differenceInDays, 'week');
        }

        if (difference < year) {
            const differenceInMonths = Math.round(difference / month);
            return rtf.format(-differenceInMonths, 'month');
        }

        const differenceInYears = Math.round(difference / year);

        return rtf.format(-differenceInYears, 'year');
    }, [language]);
};

export default useFormatDate;
