import { defaultRaceLocation, RaceLocation, RaceLocationResource } from '../RaceLocation/RaceLocation';

export interface CircuitResource {
    circuitId: string;
    circuitName: string;
    Location: RaceLocationResource;
}

export interface Circuit {
    id: string;
    name: string;
    location: RaceLocation;
}

export const defaultCircuit = (): Circuit => ({
    id: '',
    name: '',
    location: defaultRaceLocation(),
});
