import { transformToConstructor } from '../Constructor/ConstructorTransformers';
import { Driver } from '../Driver/Driver';
import { transformToDriver } from '../Driver/DriverTransformers';
import { SprintRaceResult, SprintRaceResultResource } from './SprintRaceResult';

const getStatus = (driver: Driver, status: string): string => {
    if (driver.lastName === 'Leclerc' && status === 'Accident') {
        return 'Inchident';
    }

    return status;
};

export const transformToSprintRaceResult = (
    sprintRaceResultResource: SprintRaceResultResource,
): SprintRaceResult => {
    const driver = transformToDriver(sprintRaceResultResource.Driver);

    return ({
        position: Number(sprintRaceResultResource.position),
        driver,
        constructor: transformToConstructor(sprintRaceResultResource.Constructor),
        amountOfLaps: Number(sprintRaceResultResource.laps),
        time: sprintRaceResultResource.Time
            ? sprintRaceResultResource.Time.time
            : getStatus(driver, sprintRaceResultResource.status),
        points: Number(sprintRaceResultResource.points),
    });
};
