import { isSSR } from '../../helpers';
import { isDOMException } from '../../helpers/error';
import trans from '../../helpers/trans';
import { FetchCollectionResult, FetchResultType, rejectFetchError } from '../FetchResult';
import { NextYearCalendar, NextYearCalendarResource } from './NextYearCalendar';
import { transformToNextYearCalendar } from './NextYearCalendarTransformers';

let nextYearCalendarAbortController = new AbortController();

export const getNextYearCalendarApiCall = async (): Promise<FetchCollectionResult<NextYearCalendar, string>> => {
    if (!isSSR) {
        nextYearCalendarAbortController.abort();
        nextYearCalendarAbortController = new AbortController();
    }

    try {
        const response = await fetch('/calendars/2025.json', {
            signal: nextYearCalendarAbortController.signal,
        });

        if (!response.ok) {
            return rejectFetchError('getNextYearCalendarApiCall', response);
        }

        const nextYearCalendarResource: NextYearCalendarResource = await response.json();
        const nextYearCalendar = transformToNextYearCalendar(nextYearCalendarResource);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: nextYearCalendar,
        };
    } catch (error: unknown) {
        console.error('[getNextYearCalendarApiCall]', error);

        if (isDOMException(error) && error.name === 'AbortError') {
            return {
                type: FetchResultType.Abort,
                error: trans('errors.userAborted'),
            };
        }

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
