/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconBell: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={76.2} viewBoxHeight={100} className={className}>
        <path d="M38.1,100c7,0,12.7-5.7,12.7-12.7H25.4C25.4,94.3,31.1,100,38.1,100z" />
        <path d="M69.3,44.9c-7.6-6.4-7.2-26.2-7.2-26.2C62.1,5.4,49.4,0,38.8,0c-0.2,0-1.1,0-1.3,0C26.8,0,14.1,5.4,14.1,18.7c0,0,0.4,19.8-7.2,26.2C3.1,48,0,51.9,0,56.1v13.8c0,2.7,2.2,4.9,5,4.9h66.2c2.8,0,5-2.2,5-4.9V56.1C76.2,51.9,73.1,48,69.3,44.9z" />
    </Svg>
);

export default IconBell;
/* eslint-enable max-len */
